import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
  APP_INITIALIZER,
  PLATFORM_ID,
  Optional
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CrystalGalleryModule } from 'ngx-crystal-gallery';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { DragScrollModule } from 'ngx-drag-scroll';
// import { SortablejsModule } from 'ngx-sortablejs';
import { NouisliderModule } from 'ng2-nouislider';
import 'mousetrap';

import { SidenavService } from '~/core/services/sidenav/sidenav.service';
import { ErrorInterceptor } from '~/core/interceptors/error.interceptor';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { ApiInterceptor } from '~/core/interceptors/api.interceptor';
import { MaterialModule } from '~/modules/material.module';
import { AppRoutingModule } from '~/app-routing.module';
import { StoreModule } from '~/modules/store.module';
import { VolvoDirectives } from '~/core/directives';
import { VolvoComponents } from '~/components';
import { AppComponent } from '~/app.component';
import { VolvoPipes } from '~/core/pipes';
import { VolvoPopups } from '~/popups';
import { VolvoPages } from '~/pages';
import { TokenService } from './core/services/auth/token.service';
import { Subscription } from 'rxjs';
import { LocalesStateLangList } from './core/store/locales/locales.model';
import { LocalesService } from './core/store/locales/locales.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { CacheApiService } from './core/api/cache/cache-api.service';
import { SignalrService } from './core/services/signalr/signalr-service';
import { TranslationService } from './core/services/translations/translation-service';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'angular-crumbs-2';
import { PrintService } from './core/services/print/print.service';
import { BreadCrumbUrlService } from './core/services/breadcrumb/breadcrumbUrl.service';
import { CounterService } from './core/services/counter/counter-service';
import { isPlatformBrowser } from '@angular/common';
import { CRAWLER_AGENTS, VIEWER } from './core/constants/constants';
import { NotificationService } from 'src/app/core/services/notification/notification-service';
import { TruckModelService } from '../app/core/services/truckmodel/truckmodel-service'
import { translateModule } from '@gilsdav/ngx-translate-router';

import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GallerizeModule } from '@ngx-gallery/gallerize';
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SiteCheckService } from './core/services/siteName/site-check.service';
import { RegionCheckService } from './core/services/siteName/region-check.service';
import { CommonBreadcrumbService } from './core/services/breadcrumb/commonBreadcrumb.service';
import { AssetService } from './core/services/assets/asset.service';
import { TruckModelSkeletonComponent } from './pages/skeletonTemplates/truck-model-skeleton/truck-model-skeleton.component';
import { TruckModelHomeSkeletonComponent } from './pages/skeletonTemplates/truck-model-home-skeleton/truck-model-home-skeleton.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MapsService } from './core/services/maps/maps.service';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: "d2c8eedd-7cc4-42a5-a6fc-78eb26466559",
      authority: "https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603",
      redirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            // return;
          }
          console.log(`[MSAL] ${message}`);
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: true,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  // protectedResourceMap.set('http://localhost:4200/en/search', ['user.read']);
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ["*"]
    },
    loginFailedRoute: '/login-failed'
  };
}

export function providerFactory(provider: any) {
  return () => provider.load();
}

export function viewerFactory(platformId, req: Request): string {
  if (isPlatformBrowser(platformId)) {
    return 'user';
  }
  const userAgent = (req.headers['user-agent'] || '').toLowerCase();
  const isCrawler = CRAWLER_AGENTS.some(crawlerAgent => userAgent.indexOf(crawlerAgent) !== -1);
  return isCrawler ? userAgent : 'user';
}

@NgModule({
  declarations: [
    AppComponent,
    ...VolvoPipes,
    ...VolvoDirectives,
    ...VolvoPopups,
    ...VolvoComponents,
    ...VolvoPages,
    TruckModelSkeletonComponent,
    TruckModelHomeSkeletonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    FilterPipeModule,
    DragScrollModule,
    // CrystalGalleryModule,
    NouisliderModule,
    RecaptchaModule,
    // SortablejsModule.forRoot({ animation: 150 }),
    BreadcrumbModule,
    GalleryModule,
    // NgxGalleryModule,
    TranslateModule,
    NgDynamicBreadcrumbModule,
    LightboxModule,
    GallerizeModule,
    MsalModule,
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorInterceptor
    },
    CacheApiService,
    SidenavService,
    PrintService,
    BreadCrumbUrlService,
    SignalrService,
    TranslationService,
    CounterService,
    SiteCheckService,
    RegionCheckService,
    TokenService,
    NotificationService,
    AssetService,
    TruckModelService,
    CommonBreadcrumbService,
    MapsService,
    DeviceDetectorService,
    {
      provide: APP_INITIALIZER,
      useFactory: (tokenService: TokenService) => () => tokenService.load(false),
      deps: [TokenService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (assetService: AssetService) => () => assetService.loadAssetMap(),
      deps: [AssetService],
      multi: true
    },
    ...VolvoPipes
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  private s0: Subscription;
  lang: LocalesStateLangList;
  browserLang: string;

  constructor(private globalUtils: GlobalUtils, private http: HttpClient, private localesService: LocalesService) {
    try {

      this.browserLang = navigator.language.split('-')[0].toLowerCase();
      this.initSubscriptions();
    } catch (err) {
      console.warn(err);
    }
  }

  private initSubscriptions(): void { }
}
