<i (click)="onClose()" class="email-close material-icons paddingTop" tabindex="0" (keydown.enter)="onClose()" role="button" aria-label="Close">close</i>

<div class="email">
  <div class="VolvoLogo email-logo">
    <img alt="Volvo Selected" [src]="assetService.getAssetUrl('assets/logo.png')" />
    <span class="email-logo-text">
        <b>
          {{ (region === regionEnum.UK) ? 'Used Truck Centres' : (isSelectedSite ? 'Selected' : 'Trade & Export Trucks') }}
        </b>
      <br>
      <span class="email-logo-subText">
        {{ region === regionEnum.UK ? 'United Kingdom' : (isSelectedSite ? 'Used Trucks' : 'UK & Worldwide') }}
      </span>
      
    </span>
  </div>
  <div class="email-header">
    <span class="email-header-title">
      {{ 'popups/emailUs/subtitle' | lowercase | translate }}
    </span>
  </div>
  <div class="email-EmailUs">
    <div *ngIf="data.truck?.pictures && data.truck.pictures[0]" class="email-EmailUs-Image">
      <img [src]="data.truck.pictures[0].filename | volvoCdn | escapeUrl" volvoLazyImage [alt]="data.truck?.make" />
    </div>

    <div class="email-EmailUs-Info" *ngIf="isSelectedSite; else nonSelected;">
      <span class="email-EmailUs-Title">
        {{ data.truck?.specification.make || '----' }}  {{ data.truck?.specification.model || '----' }}
      </span>
      <div style="display: flex;" class="email-EmailUs-Text paddingTop5">
        <span>
          {{ data.truck?.specification.category === 'Trailer' ? ('specification/trailerId' | lowercase | translate) : ('specification/truckId' | lowercase | translate) }}:
        </span>
        <span>&nbsp;{{ data.truck.specification.licensePlate || '----' }}</span>
      </div>
      <div *ngIf="data.truck?.stockLocation?.region === 'ZA';else nonZA">
        <h5 *ngIf="data.truck?.pricing?.priceExclVat.value" class="email-EmailUs-SubTitle paddingTop5">
          {{ data.truck?.pricing?.priceExclVat.value | number: '1.0-0':'de' }} R
          <span *ngIf="data.truck.ShowPrice" class="MessageUs-infoVat ng-star-inserted CPrimary">{{ ' ' }}{{ 'truck/vat' | lowercase | translate }}
          </span>
        </h5>
      </div>
      <ng-template #nonZA>
        <div *ngIf="data.truck?.pricing?.priceExclVatEuro" class="email-EmailUs-SubTitle paddingTop5">
          {{ data.truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' }} €
          <span *ngIf="data.truck.ShowPrice" class="MessageUs-infoVat ng-star-inserted CPrimary">{{ ' ' }}{{ 'truck/vat' | lowercase | translate }}
          </span>
        </div>
      </ng-template>
      
      <div *ngIf="!data.truck?.ShowPrice" class="email-EmailUs-SubTitle CPrimary paddingTop5" style="font-size: 18px">
        {{ 'truck/askPrice' | lowercase | translate }}
      </div>

      <div *ngIf="data.truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && data.truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR"
        class="email-EmailUs-LocalPrice paddingTop5">
        <span *ngIf="data.truck.pricing?.priceExclVat?.value">
          {{ 'globalMiss/priceLocalCurrency' | lowercase | translate }}: {{ data.truck.pricing?.priceExclVat?.value | number: '1.0-0':'de'
          }} {{ data.truck.pricing?.priceExclVat?.currencyIsoCode3 }}
        </span>
      </div>
    </div>
  </div>
  <ng-template #nonSelected>
    <div class="email-EmailUs-Info">
      <span class="email-EmailUs-Title">
        {{ data.truck?.specification.make || '----' }}  {{ data.truck?.specification.model || '----' }}
      </span>
      <div style="display: flex;" class="email-EmailUs-Text paddingTop5">
        <span>
          {{ data.truck?.specification.category === 'Trailer' ? ('specification/trailerId' | lowercase | translate) : ('specification/truckId' | lowercase | translate) }}:
        </span>
        <span>&nbsp;{{ data.truck.specification.licensePlate || '----' }}</span>
      </div>
      <div  class="email-EmailUs-Text paddingTop5">
        <span>
            {{ data.truck?.specification.axleConfiguration | lookup }} {{ data.truck?.specification.horsePower | hasValue }} {{ data.truck?.specification.category
            | hasValue }}
        </span>
      </div>
      <div *ngIf="data.truck.advertisementCategory=='Remaining Warranty'" class="email-EmailUs-LocalPrice paddingTop5">{{data.truck?.advertisementCategory}}</div>
      <div *ngIf="data.truck.advertisementCategory!='Remaining Warranty'" class="email-EmailUs-LocalPrice paddingTop5">Volvo {{data.truck?.advertisementCategory}}</div>
    </div>
  </ng-template>
  <div>
    <div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="email-input">
          <div>
            <div>
              <h6 class="email-input-title">{{ 'form/fullName' | lowercase | translate }} </h6>
                <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                  <input formControlName="name" matInput [(ngModel)]="name" (change)="changeMessageName()" />
                </mat-form-field>
            </div>

            <div>
              <h6 class="email-input-title">{{ 'form/email' | lowercase | translate }}</h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                <input formControlName="email" matInput />
              </mat-form-field>
            </div>

            <div>
              <h6 class="email-input-title">
                {{ 'form/phoneNumber' | lowercase | translate }}
                <span class="grey3">({{ 'form/optional' | lowercase | translate }})</span>
              </h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                <input formControlName="phone" matInput />
              </mat-form-field>
            </div>
            
            <div class="email-financing MatInputSearchEmail" *ngIf="region !== 'TX'">
              <mat-checkbox formControlName="financing" tabindex="0" (keydown.enter)="onCheckboxToggle($event)">
              </mat-checkbox>
              <div class="email-input-checkBoxText grey3">
                {{ 'form/financingCheckbox' | lowercase | translate }}
              </div>
            </div>

            <div *ngIf="form.get('financing').value && isSelectedSite" class="MessageUs-Financing">
              <div>
                <div>
                  <h6 class="email-input-title">{{ 'form/companyName' | lowercase | translate }}</h6>
                  <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                    <input formControlName="financingCompanyName" matInput />
                  </mat-form-field>
                </div>

                <div>
                  <h6 class="email-input-title">{{ 'form/city' | lowercase | translate }}</h6>
                  <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                    <input formControlName="financingCity" matInput />
                  </mat-form-field>
                </div>

                <div>
                  <h6 class="email-input-title">{{ 'form/country' | lowercase | translate }}</h6>
                  <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                    <input formControlName="financingCountry" matInput />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div *ngIf = "!isSelectedSite">
              <div>
                <h6 class="email-input-title">{{ 'uk/form/companyName' | lowercase | translate }} 
                  <span *isTXSite class="grey3">({{ 'form/optional' | lowercase | translate }})</span>
                </h6>
                <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                  <input formControlName="companyName" matInput />
                </mat-form-field>
              </div>
              
              <div *ngIf="region === 'TX'; else others">
                <h6 class="email-input-title">Country 
                    <!-- {{ 'uk/form/country' | lowercase | translate }} -->
                    <span class="grey3">({{ 'form/optional' | lowercase | translate }})</span>
                </h6>
                <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                    <input formControlName="country" matInput />
                </mat-form-field>
              </div>
              
              <ng-template #others>
                <div>
                  <h6 class="email-input-title">{{ 'uk/form/postcode' | lowercase | translate }} or {{ 'uk/form/location' | lowercase | translate }}</h6>
                  <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                    <input formControlName="postCodeOrLocation" matInput />
                  </mat-form-field>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="divWidth">
            <div style="text-align: left;">
              <h6 class="email-input-title">
                {{ 'form/message' | lowercase | translate }}
                <span class="grey3">({{ 'form/optional' | lowercase | translate }})</span>
              </h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                <textarea formControlName="message" matInput rows="5"  placeholder="{{'form/email/messageText' | lowercase | translate}}" value="{{'form/email/messageText' | lowercase | translate}} {{name}}"></textarea>
              </mat-form-field>
            </div>

            <div class="MatInputSearchEmail privacyCheckboxPadding" *ngIf = "isSelectedSite; else nonSelectedSites">
              <mat-checkbox (click)="recaptcha()" formControlName="privacy" (keydown.enter)="onCheckboxToggle($event)">
                <div class="email-privacy grey3">
                  {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
                  <a class="PopupOLD-newLink privacyPolicy" href="https://www.volvogroup.com/en-en/privacy.html" target="_blank">
                    <span>{{ 'form/privacyPolicy' | lowercase | translate }}</span>
                  </a>
                </div>
              </mat-checkbox>
            </div>
            <ng-template #nonSelectedSites>
              <div class="ukPaddingTop15">
                <div class="email-privacy grey4 unSetWhiteSpace">
                  {{ 'uk/form/privacy' | lowercase | translate }}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="recaptcha">
            <div class="email-submit">
              <div class="submitdiv" *ngIf="isSelectedSite">
                <div *ngIf="showRecaptcha" style="display: inline-block">
                  <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required></re-captcha>
                </div>
              </div>
              <button [disabled]="form.invalid && (!reCaptchaRes || !isSelectedSite)" type="submit" [ngClass]="(!form.invalid && reCaptchaRes) || (!form.invalid && !isSelectedSite) ? 'email-submit-btnColor' : ''">
                {{ 'form/send' | lowercase | translate }}
              </button>
            </div>
        </div>
      </form>
    </div>
  </div>
</div>