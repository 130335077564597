<mat-chip-list class="Search-chip">
    <ng-container *ngFor="let keyValueObj of search.steps | keyvalue">
        <ng-container *ngFor="let val of checkAndSplit(keyValueObj.value.value)">
            <div class="scroll" *ngIf="val !== ''" (keydown.enter)="resetAllFilters()"
            (keydown.space)="resetAllFilters()">
                <mat-chip *ngIf="
          keyValueObj.value.isSelected &&
          (keyValueObj.value.isVisible || keyValueObj.value.value === search.steps.stockLocationAddress.value) &&
          keyValueObj.key !== 'advertisementCategory' &&
          keyValueObj.key !== 'requestingFilter' &&
          keyValueObj.key !== 'expandingFilter'
        " [removable]="true" (removed)="resetStep(keyValueObj.key,val)" [disabled]="fromNotification" (keydown.enter)="resetStep(keyValueObj.key,val)"
        (keydown.space)="resetStep(keyValueObj.key,val)">
                    <ng-container *ngIf="
            keyValueObj.key === SearchStepKeys.priceExclVatEuro ||
              keyValueObj.key === SearchStepKeys.horsePower ||
              keyValueObj.key === SearchStepKeys.mileageKm ||
              keyValueObj.key === SearchStepKeys.dateFirstRegistration;
            else notSlider
          ">
                        <span style="text-transform:lowercase;"
                              *ngIf="keyValueObj.key!== SearchStepKeys.dateFirstRegistration && keyValueObj.key!== SearchStepKeys.priceExclVatEuro">
                            {{
            displayMinMaxValue(keyValueObj.value.value.min,keyValueObj.value.value.max)
                            }}
                            {{ keyValueObj.value.options.units }}
                        </span>

                        <span *ngIf="keyValueObj.key === SearchStepKeys.priceExclVatEuro && regionSettings.site==='volvoselected'">
                            {{
            displayMinMaxValue(keyValueObj.value.value.min,keyValueObj.value.value.max)
                            }}
                            {{ keyValueObj.value.options.units }}
                        </span>

                        <span *ngIf="keyValueObj.key=== SearchStepKeys.dateFirstRegistration">
                            {{ keyValueObj.value.value.min }} - {{
            keyValueObj.value.value.max
                            }}
                            {{ keyValueObj.value.options.units }}
                        </span>
                    </ng-container>

                    <div *ngFor="let truckType of search.steps.majorCategory.list">
                        <span *ngIf="
              keyValueObj.key === 'majorCategory' &&
              keyValueObj.value.value === truckType.name &&
              truckType.name === 'Truck'
            ">
                            {{ 'globalMiss/truck' | lowercase | translate }}
                        </span>
                        <span *ngIf="
              keyValueObj.key === 'majorCategory' &&
              keyValueObj.value.value === truckType.name &&
              truckType.name === 'Tractor'
            ">
                            {{ 'globalMiss/tractor' | lowercase | translate }}
                        </span>
                        <span *ngIf="
              keyValueObj.key === 'majorCategory' &&
              keyValueObj.value.value === truckType.name &&
              truckType.name === 'Trailer'
            ">
                            {{ 'globalMiss/trailer' | lowercase | translate }}
                        </span>
                    </div>
                    <span *ngIf="keyValueObj.value.value === search.steps.stockLocationCountry.value">
                        {{
          ('search/filter/country/' + search.steps.stockLocationCountry.value | lowercase | translateMany) ===
          ('search.filter.country.' + search.steps.stockLocationCountry.value | lowercase)
          ? val
          : ('search/filter/country/' + val | lowercase | translateMany)
                        }}
                    </span>

                    <span *ngIf="keyValueObj.value.value === search.steps.make.value">{{ val }}</span>

                    <span *ngIf="keyValueObj.value.value === search.steps.model.value">{{ val }}</span>

                    <span *ngIf="keyValueObj.value.value === search.steps.cabType.value">{{ val }}</span>
                    <span *ngIf="keyValueObj.value.value === search.steps.offerAndWarranty.value">
                        Volvo {{
          val
                        }}
                    </span>
                    <span *ngIf="keyValueObj.value.value === search.steps.stockLocationCity.value">
                        {{
          val
                        }}
                    </span>
                    <span *ngIf="keyValueObj.value.value === search.steps.axleConfiguration.value">
                        {{
          val
                        }}
                    </span>

                    <span *ngIf="keyValueObj.value.value === search.steps.emission.value">{{ val }}</span>
                    <span *ngIf="keyValueObj.value.value === search.steps.superstructureType.value">
                        {{
          'search/filter/country/superstructure/' + val | translateMany
                        }}
                    </span>
                    <span *ngIf="keyValueObj.value.value === search.steps.stockLocationAddress.value">
                        {{
          val
                        }}
                    </span>
                    <span *ngIf="keyValueObj.value.value === search.steps.fuelType.value">
                        {{
 'search/filter/fueltype/' +
          val | translateMany
                        }}
                    </span>
                    <span *ngIf="keyValueObj.value.value === search.steps.chassisHeight.value">
                        {{
 'search/filter/chassisheight/' +
          val | lowercase | translateMany
                        }}
                    </span>
                    <span *ngIf="keyValueObj.value.value === search.steps.priceExclVatEuro.value && regionSettings.site!=='volvoselected'">
                        Max. £{{ (search.steps.priceExclVatEuro.value | asSlider).max | number:'':'en-GB'}}
                    </span>
                    <i matChipRemove class="far fa-times"></i>
                </mat-chip>
            </div>
        </ng-container>
    </ng-container>

    <div class="delete" tabindex="0"
        (click)="resetAllFilters()"
        (keydown.enter)="resetAllFilters()"
        (keydown.space)="resetAllFilters()">
        <mat-chip *ngIf="(selectedFiltersCount||dealershipLocationCount) > 0 && !fromNotification"
                    style="background-color: #F7F7F7">
            <span>
                {{ 'globalMiss/resetFilter' | lowercase| translate }}
            </span>
        </mat-chip>
    </div>
</mat-chip-list>
