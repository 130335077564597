<section class="whoweare">
    <div class="tabMobSidePadding">
        <div class="whoweare-title">
            <span>{{ "uk/pages/aboutUs/whatcustomersay" | lowercase | translate }}</span>
        </div>
        <div class="whoweare-whatCustomerSayText mobileDisplay">
            <span>{{ "uk/pages/aboutUs/whatcustomersayintro" | lowercase | translate }}</span>
        </div>
        <div class="whoweare-text paddingBottom">
            <div class="content-container">
                <!-- Video Section -->
                <div>
                    <div class="video-wrapper">
                        <iframe
                            width="100%"
                            height="100%"
                            [src]="videoUrl"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            [ngClass]="isIframeLoaded? 'video-height' : 'skeletonYoutube'"
                        ></iframe>
                    </div>
                    <div class="contentview" style="display: block;"> 
                        <a class="contentview" href='https://www.youtube.com/@VolvoTrucksUK' target="_blank"><img [src]="assetService.getAssetUrl('assets/icons/youtube.svg')" alt="{{ 'pages/home/youtube/view' | lowercase | translate }}">{{"pages/home/youtube/view"| lowercase | translate}} </a>
                    </div>
                </div>
               
                <!-- Text Section -->
                <div class="text-wrapper">
                  <h3>{{"pages/aboutus/video/header" | lowercase | translate}}</h3>
                  <p>
                    {{"pages/aboutus/video/text1" | lowercase | translate}}
                  </p>
                  <p>
                    {{"pages/aboutus/video/text2" | lowercase | translate}}
                  </p>
                </div>
              </div>
        </div>
        <div class="whoweare-text paddingBottom">
            <div class="Testimony-1">
                <div class="flexStyle">
                    <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Nick Brown  Transport.webp')" class="Testimony-1-imageDiv" alt=""/>
                    <div>
                        <div class="Testimony-1-text">
                            <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                                <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext1' | lowercase | translate"></div>
                            </div>
                        </div>
                        <div class="testimonyTitle">
                            {{ "uk/pages/aboutUs/nickBrown" | lowercase | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flexStyle">
                <div class="Testimony-2 rightMargin">
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/66 Logistics_option2.webp')" class="Testimony-2-imageDiv" alt=""/>
                    </div>
                    <div class="Testimony-2-text">
                        <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                            <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext2' | lowercase | translate"></div>
                        </div>
                    </div>
                    <div class="testimonyTitle">
                        {{ "uk/pages/aboutUs/andrew" | lowercase | translate }}
                    </div>
                </div>
                <div class="Testimony-3">
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Ciceo Bella.webp')" class="Testimony-3-imageDiv" alt=""/>
                    </div>
                    <div class="Testimony-3-text">
                        <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                            <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext3' | lowercase | translate"></div>
                        </div>
                    </div>
                    <div class="testimonyTitle">
                        {{ "uk/pages/aboutUs/ciceo" | lowercase | translate }}
                    </div>
                </div>
            </div>
            <div class="Testimony-4">
                <div class="flexStyle">
                    <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Shelvey Haulage.webp')" class="Testimony-4-imageDiv" alt=""/>
                    <div>
                        <div class="Testimony-4-text">
                            <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                                <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext11' | lowercase | translate"></div>
                            </div>
                        </div>
                        <div class="testimonyTitle">
                            {{ "uk/pages/aboutUs/lisa" | lowercase | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flexStyle">
                <div class="Testimony-5 rightMargin">
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/East Coast Logistics.webp')" class="Testimony-5-imageDiv" alt=""/>
                    </div>
                    <div class="Testimony-5-text">
                        <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                            <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext8' | lowercase | translate"></div>
                        </div>
                    </div>
                    <div class="testimonyTitle">
                        {{ "uk/pages/aboutUs/ged" | lowercase | translate }}
                    </div>
                </div>
                <div class="Testimony-6">
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Hawthorns Logistics.webp')" class="Testimony-6-imageDiv" alt=""/>
                    </div>
                    <div class="Testimony-6-text">
                        <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                            <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext6' | lowercase | translate"></div>
                        </div>
                    </div>
                    <div class="testimonyTitle">
                        {{ "uk/pages/aboutUs/chris" | lowercase | translate }}
                    </div>
                </div>
            </div>
            <div class="Testimony-7">
                <div class="flexStyle1">
                    <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Michael Bingham.webp')" class="Testimony-7-imageDiv" alt=""/>
                    <div>
                        <div class="Testimony-7-text">
                            <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                                <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext7' | lowercase | translate"></div>
                            </div>
                        </div>
                        <div class="testimonyTitle">
                            {{ "uk/pages/aboutUs/michael" | lowercase | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flexStyle">
                <div class="Testimony-8 rightMargin">
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/MAH UK Transport.webp')" class="Testimony-8-imageDiv" alt=""/>
                    </div>
                    <div class="Testimony-8-text">
                        <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                            <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext9' | lowercase | translate"></div>
                        </div>
                    </div>
                    <div class="testimonyTitle fontsize">
                        {{ "uk/pages/aboutUs/harry" | lowercase | translate }}
                    </div>
                </div>
                <div class="Testimony-9">
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/SandJ Pierce Transport.webp')" class="Testimony-9-imageDiv" alt=""/>
                    </div>
                    <div class="Testimony-9-text">
                        <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                            <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext10' | lowercase | translate"></div>
                        </div>
                    </div>
                    <div class="testimonyTitle">
                        {{ "uk/pages/aboutUs/steve" | lowercase | translate }}
                    </div>
                </div>
            </div>
            <div class="Testimony-10">
                <div class="flexStyle1">
                    <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Kemp Express.webp')" class="Testimony-10-imageDiv" alt=""/>
                    <div>
                        <div class="Testimony-10-text">
                            <div class="whoweare-heading-whatCustomerSayText paddingBottom">
                                <div [innerHTML]="'uk/pages/aboutUs/whatcustomersaytext4' | lowercase | translate"></div>
                            </div>
                        </div>
                        <div class="testimonyTitle">
                            {{ "uk/pages/aboutUs/andy" | lowercase | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
</section>
<div class="mobile">
    <volvo-footer ></volvo-footer>
</div>
