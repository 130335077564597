export enum LangRoutePages {
    home = '',
    markets = 'markets',
    regions = 'regions',
    search = 'search',
    truck = 'truck/:id',
    aboutUs = 'about-us',
    contact = 'contact',
    truckCollation = 'truck-collation',
    privacyPolicy = 'privacy-policy',
    cookies = 'cookies',
    // pageNotFound = '404',
    unsubscribe = 'unsubscribe',
    assets = 'assets',
    services = 'services',
    volvoSelected = 'volvoSelected',
    finance = 'finance',
    truckModels = 'truckModels',
    volvoFH = 'volvoFH',
    volvoFH16 = 'volvoFH16',
    volvoFM = 'volvoFM',
    volvoFMX = 'volvoFMX',
    vehicleOffers = 'vehicleOffers',
    buyersGuide = 'buyersGuide',
    truckWarranty = 'truck/:id',
    truckSelected = 'truck/:id',
    truckEconomy = 'truck/:id',
    warranty = 'warranty',
    all = 'all',
    selected = 'selected',
    economy = 'economy',
    vehicleWarranty = 'warranty',
    vehicleSelected = 'selected',
    vehicleApproved = 'approved',
    vehicleEconomy = 'economy',
    tradeExport = 'tradeExport',
    centreBanbury = 'Banbury',
    centreBardon = 'Leicester',
    centreEly = 'Cambridge',
    centreEnfield = 'LondonNorth',
    centreFelixStowe = 'Felixstowe',
    centreHayes = 'LondonHeathrow',
    centreReading = 'Reading',
    centreWellingBorough = 'Wellingborough',
    ourCentres = 'ourCentres',
    meetTeam = 'meetTeam',
    complaint = "complaints",
    admin = "administration",
    contactMob = '/contact',
    volvoFE = 'volvoFE',
    volvoFL = 'volvoFL',
    whoweare = 'whoweare',
    whatwehave = 'whatwehave',
    whatcustomersay = 'whatcustomersay',
    serviceApproved = 'selectedapproved',
    serviceEconomy = 'economy',
    financing = 'financing',
    serviceContract = 'contract',
    customisation = 'customisation',
    approvedFAQ = 'approvedFAQ',
    economyFAQ = 'economyFAQ',
    faq = 'faq',
    print = 'print',
    printTruck = 'printTruck',
    volvoConnect = 'volvoConnect',
    centreEdinburgh = 'Edinburgh',
    centreGlasgowWest = 'GlasgowWest',
    centreWashington = 'Washington',
    bgvolvoFE = 'volvoFE',
    bgvolvoFH = 'volvoFH',
    bgvolvoFH16 = 'volvoFH16',
    bgvolvoFL = 'volvoFL',
    bgvolvoFM = 'volvoFM',
    bgvolvoFMX = 'volvoFMX',
    serviceExport = 'export',
    serviceCustomisation = 'utcustomisation'
}

export enum HtmlRoutePages {
    home = '/',
    markets = '/markets',
    regions = '/regions',
    search = '/search',
    truck = '/search/truck',
    aboutUs = '/about-us',
    contact = '/contact',
    truckCollation = '/truck-collation',
    privacyPolicy = '/privacy-policy',
    cookies = '/cookies',
    // pageNotFound = '/404',
    unsubscribe = '/unsubscribe',
    assets = '/assets',
    services = '/services',
    volvoSelected = '/services/volvoSelected',
    finance = '/services/finance',
    truckModels = '/truckModels',
    volvoFH = '/truckModels/volvoFH',
    volvoFH16 = '/truckModels/volvoFH16',
    volvoFM = '/truckModels/volvoFM',
    volvoFMX = '/truckModels/volvoFMX',
    volvoFE = '/truckModels/volvoFE',
    volvoFL = '/truckModels/volvoFL',
    vehicleOffers = '/vehicleOffers',
    buyersGuide = '/buyersGuide',
    all = '/search/all',
    warranty = '/search/warranty',
    selected = '/search/selected',
    economy = '/search/economy',
    truckWarranty = '/search/truck',
    truckSelected = '/search/truck',
    truckEconomy = '/search/truck',
    vehicleWarranty = '/vehicleOffers/warranty',
    vehicleSelected = '/vehicleOffers/selected',
    vehicleApproved = '/vehicleOffers/approved',
    vehicleEconomy = '/vehicleOffers/economy',
    tradeExport = '/tradeExport',
    centreBanbury = '/contact/Banbury',
    centreBardon = '/contact/Leicester',
    centreEly = '/contact/Cambridge',
    centreEnfield = '/contact/LondonNorth',
    centreFelixStowe = '/contact/Felixstowe',
    centreHayes = '/contact/LondonHeathrow',
    centreReading = '/contact/Reading',
    centreWellingBorough = '/contact/Wellingborough',
    ourCentres = '/contact/ourCentres',
    ourCentreBanbury = '/contact/ourCentres/Banbury',
    ourCentreBardon = '/contact/ourCentres/Leicester',
    ourCentreEly = '/contact/ourCentres/Cambridge',
    ourCentreEnfield = '/contact/ourCentres/LondonNorth',
    ourCentreFelixStowe = '/contact/ourCentres/Felixstowe',
    ourCentreHayes = '/contact/ourCentres/LondonHeathrow',
    ourCentreReading = '/contact/ourCentres/Reading',
    ourCentreWellingBorough = '/contact/ourCentres/Wellingborough',
    meetTeam = '/contact/meetTeam',
    complaint = '/complaints',
    admin = '/administration',
    contactMob = '/contact',
    bgvolvoFE = '/buyersGuide/volvoFE',
    bgvolvoFH = '/buyersGuide/volvoFH',
    bgvolvoFH16 = '/buyersGuide/volvoFH16',
    bgvolvoFL = '/buyersGuide/volvoFL',
    bgvolvoFM = '/buyersGuide/volvoFM',
    bgvolvoFMX = '/buyersGuide/volvoFMX',
    whoweare = '/about-us/whoweare',
    whatwehave = '/about-us/whatwehave',
    whatcustomersay = '/about-us/whatcustomersay',
    serviceApproved = '/services/selectedapproved',
    serviceEconomy = '/services/economy',
    financing = '/services/financing',
    serviceContract = '/services/contract',
    customisation = '/services/customisation',
    approvedFAQ = '/services/selectedapproved/approvedFAQ',
    economyFAQ = '/services/economy/economyFAQ',
    faq = '/faq',
    volvoConnect = '/services/volvoConnect',
    centreEdinburgh = '/contact/Edinburgh',
    centreGlasgowWest = '/contact/GlasgowWest',
    centreWashington = '/contact/Washington',
    ourCentreEdinburgh = '/contact/ourCentres/Edinburgh',
    ourCentreGlasgowWest = '/contact/ourCentres/GlasgowWest',
    ourCentreWashington = '/contact/ourCentres/Washington',
    serviceExport = '/services/export',
    serviceCustomisation = '/services/utcustomisation',
    tradeExportSub = '/contact/tradeExport',
}

export enum BreadCrumbLabels {
    "search" = "pages/search",//"Our Stock",
    "FH" = "Volvo FH",
    "FE" = "Volvo FE",
    "FH16" = "Volvo FH16",
    "FL" = "Volvo FL",
    "FM" = "Volvo FM",
    "FMX" = "Volvo FMX",
    "buyersGuide" = "pages/buyersguide",//"Buyer's Guide",
    "volvofe" = "Volvo FE",
    "volvofh" = "Volvo FH",
    "volvofh16" = "Volvo FH16",
    "volvofl" = "Volvo FL",
    "volvofm" = "Volvo FM",
    "volvofmx" = "Volvo FMX",
    "vehicleOffers" = "pages/vehiclecategories",//"Vehicle Offers",
    "warranty" = "pages/search/remainingwarranty",// "Remaining Warranty",
    "selected" = "Volvo Selected",
    "approved" = "Volvo Approved",
    "economy" = "Volvo Economy",
    "services" = "pages/service",//"Services",
    "volvoSelected" = "Volvo Selected",
    "financing" = "form/financing",//"Financing",
    "approvedFAQ" = "FAQ",
    "economyFAQ" = "FAQ",
    "selectedapproved" = "Volvo Selected & Approved",
    "finance" = "form/financing",//"Financing",
    "contract" = "pages/services/volvoselected/servicecontracts",//"Service contract",
    "customisation" = "components/footer/customisation",//"Customisation",
    "volvoConnect" = "Volvo Connect",
    "export" = "pages/services/export",// "Export (Worldwide)",
    "utcustomisation" = "pages/services/utcustomisation",// "Used Truck Customisation",
    "truckModels" = "components/footer/truckmodel",//"Truck Models",
    "volvoFH16" = "Volvo FH16",
    "volvoFH" = "Volvo FH",
    "volvoFM" = "Volvo FM",
    "volvoFMX" = "Volvo FMX",
    "contact" = "pages/contact",//"Contact Us",
    "meetTeam" = "pages/contact/contacttitle",//"Meet the Team",
    "ourCentres" = "pages/contact/ourusertruckcentre",//"Our Used Truck Centre",
    "Banbury" = "Banbury",
    "Leicester" = "Leicester",
    "Cambridge" = "Cambridge",
    "LondonNorth" = "London North",
    "Felixstowe" = "Felixstowe",
    "LondonHeathrow" = "London North",
    "Reading" = "Reading",
    "Wellingborough" = "Wellingborough",
    "Edinburgh" = "Edinburgh",
    "GlasgowWest" = "Glasgow West",
    "Washington" = "Washington",
    "about-us" = "pages/aboutus",//"About Us",
    "whoweare" = "pages/aboutus/whoweare",//"Who we are",
    "whatcustomersay" = "pages/aboutus/whatcustomersay",//"What our customer say"
}
