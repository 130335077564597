<div *ngIf="hasFilters; else others" class="heightAdjustmentMobile">
<div class="notification" *ngIf="!submitted">
  <div class="notification-mainHeader mobileMargin">
    <img [src]="assetService.getAssetUrl('assets/icons/bell.svg')" alt="{{ 'search/notification' | lowercase | translate }}"/>
    <span class="notification-mainHeader-headerText mobileTextCss">{{ 'search/notification' | lowercase | translate }}</span>
    <i (click)="onClose()" class="notification-mainHeader-closeBtn material-icons mobileBtnCss" tabindex="0" (keydown.enter)="onClose()" 
    (keydown.space)="onClose()" role="button" 
    aria-label="Close notification">close</i>
  </div>
  <div class="notification-Text notification-padding24">
    <div class="notification-Text-regular">
      {{ 'search/notification/filterText' | lowercase | translate }}
    </div>
    <div>
      <volvo-search-chip [fromNotification]="false"></volvo-search-chip>
    </div>
  </div>
  <div class="notification-Text">
    <div class="notification-Text-bold">
      {{ 'search/notification/contactText' | lowercase | translate }}
    </div>
  </div>
  <div class="notification-form">
    <form [formGroup]="notificationForm" (ngSubmit)="onSubmit()">
      <div></div>
      <div>
        <div class="notification-form-Input">
          <div class="notification-form-Input-display" [ngClass]="isEmailValid ? 'input-sucess' :  'input-error'">
            <div class="notification-form-Input-paddingTop">
              <input id="email" type="email" formControlName="email" [placeholder]="'search/notification/email' | lowercase | translate"
              />
            </div>
            <div *ngIf="notificationForm.get('email').valid" class="notification-form-Input-paddingTop">
              <img [src]="assetService.getAssetUrl('assets/icons/tick.svg')" alt="Validated" />
            </div>
          </div>
        </div>
        <div *ngIf="notificationForm.get('email').valid" class="notification-padding24">
          <div class="notification-Text notification-Text-regular notification-form-details">
            {{ 'search/notification/details' | lowercase | translate }}
            <span class="notification-Text-bold-regular">({{ 'form/optional' | lowercase | translate }}):</span>
          </div>
          <div>
            <div class="notification-Text-bold notification-padding16">
              {{ 'cookie/table/head/tdb' | lowercase | translate }}

            </div>
            <div class="notification-form-Input">
              <div class="notification-form-Input-display" [ngClass]="(notificationForm.get('name').valid ? 'input-sucess' : (!notificationForm.get('name').value ? 'input-sucess': 'input-error'))">
                <div class="notification-form-Input-paddingTop">
                  <input id="name" type="text" formControlName="name" [placeholder]="'search/notification/fullName' | lowercase | translate "
                  />
                </div>
                <div *ngIf="notificationForm.get('name').value" class="notification-form-Input-paddingTop">
                  <img [src]="assetService.getAssetUrl('assets/icons/tick.svg')" alt="Validated" />
                </div>
              </div>
            </div>
            <div class="notification-Text-bold notification-padding16">
              {{ 'form/phoneNumber' | lowercase | translate }}
            </div>
            <div class="notification-form-Input">
              <div class="notification-form-Input-display" [ngClass]="(isValidPhoneno && notificationForm.get('phone').valid  ? 'input-sucess' : (!notificationForm.get('phone').value ? 'input-sucess': 'input-error'))">
                <div class="notification-form-Input-paddingTop">
                  <input id="name" type="text" formControlName="phone" [placeholder]="'search/notification/phone' | lowercase | translate "
                  (change)="phoneNumberChange()" />
                </div>
                <div *ngIf="isValidPhoneno && notificationForm.get('phone').value && notificationForm.get('phone').valid" class="notification-form-Input-paddingTop">
                  <img [src]="assetService.getAssetUrl('assets/icons/tick.svg')" alt="Validated" />
                </div>
              </div>
            </div>

            

            <div *isSelectedSite>
              <div class="notification-Text-bold notification-padding16">
                {{ 'form/country' | lowercase | translate }}
              </div>
              <div class="notification-form-Input">
                <div class="notification-form-Input-display" [ngClass]="(isValidPhoneno && notificationForm.get('country').valid  ? 'input-sucess' : (!notificationForm.get('country').value ? 'input-sucess': 'input-error'))">
                  <!-- <div class="notification-form-Input-paddingTop">
                    <img [src]="assetService.getAssetUrl('assets/icons/location.svg')" />
                  </div> -->
                  <div class="notification-form-Input-countryDD">
                    <mat-select [(ngModel)]="currentLang" formControlName="country" [placeholder]="'search/notification/country' | lowercase | translate">
                      <mat-option class="CPrimary" *ngFor="let country of countryDropDown" [value]="country.id">{{ country.label }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <!-- <div *ngIf="notificationForm.get('country').value">
                    <img [src]="assetService.getAssetUrl('assets/icons/tick.png')" />
                  </div> -->
                </div>
              </div>
            </div>
            <div *isTXSite>
              <div class="notification-Text-bold notification-padding16">
                {{ 'form/country' | lowercase | translate }}
              </div>
              <div class="notification-form-Input">
                <div class="notification-form-Input-display" [ngClass]="(notificationForm.get('country').valid ? 'input-sucess' : (!notificationForm.get('country').value ? 'input-sucess': 'input-error'))">
                  <div class="notification-form-Input-paddingTop">
                    <input id="country" type="text" formControlName="country" [placeholder]="'search/notification/country' | lowercase | translate "
                    />
                  </div>
                  <div *ngIf="notificationForm.get('country').value" class="notification-form-Input-paddingTop">
                    <img [src]="assetService.getAssetUrl('assets/icons/tick.svg')" alt="Validated" />
                  </div>
                </div>
              </div>
            </div>
            <div class="notification-Text-bold notification-padding16">
              {{ 'form/company' | lowercase | translate }}
            </div>
            <div class="notification-form-Input">
              <div class="notification-form-Input-display" [ngClass]="(notificationForm.get('company').valid ? 'input-sucess' : (!notificationForm.get('company').value ? 'input-sucess': 'input-error'))">
                <!-- <div class="notification-form-Input-paddingTop">
                  <img [src]="assetService.getAssetUrl('assets/icons/company.svg')" />
                </div> -->
                <div class="notification-form-Input-paddingTop">
                  <input id="company" type="text" formControlName="company" [placeholder]="'search/notification/company' | lowercase | translate"
                  />
                </div>
                <div *ngIf="notificationForm.get('company').value" class="notification-form-Input-paddingTop">
                  <img [src]="assetService.getAssetUrl('assets/icons/tick.svg')" alt="Validated" />
                </div>
              </div>
            </div>

            <div *ngIf = "!isSelectedSite && region !== regionEnum.TX">
              <div class="notification-Text-bold notification-padding16">
                {{'uk/search/notification/postcode' | lowercase | translate }}
              </div>
              <div class="notification-form-Input">
                <div class="notification-form-Input-display" [ngClass]="(isValidPostcode ? 'input-sucess' : (!notificationForm.get('postcode').value ? 'input-sucess': 'input-error'))">
                  <div class="notification-form-Input-paddingTop">
                    <input id="postcode" type="text" formControlName="postcode" placeholder="E.g. CV34 5YA" (change)="postcodeChange()" />
                  </div>
                  <div *ngIf="isValidPostcode && notificationForm.get('postcode').value" class="notification-form-Input-paddingTop">
                    <img [src]="assetService.getAssetUrl('assets/icons/tick.svg')" alt="Validated" />
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <!-- <div class="notification-Text notification-form-details">
            {{ 'search/notification/notified' | lowercase | translate }}
          </div> -->
          <!-- <div>
            <div class="notification-form-slide">
              <div class="custom-slide">
                <input id="instantly" type="checkbox" name="instantly" formControlName="instantly" (click)="toogleSlideButton('instantly')"/>
                <label for="instantly">
                  <div class="switch" attr.data-unchecked="{{ 'search/notification/instant' | lowercase | translate }}"
                    attr.data-checked="{{ 'search/notification/instant' | lowercase | translate }}"></div>
                </label>
              </div>
              <div>
                <div class="custom-slide">
                  <input id="daily" type="checkbox" name="daily" formControlName="daily" (click)="toogleSlideButton('daily')" />
                  <label for="daily">
                    <div class="switch" attr.data-unchecked="{{ 'search/notification/daily' | lowercase | translate }}"
                      attr.data-checked="{{ 'search/notification/daily' | lowercase | translate }}"></div>
                  </label>
                </div>
              </div>
              <div>
                <div class="custom-slide">
                  <input id="weekly" type="checkbox" name="weekly" formControlName="weekly" (click)="toogleSlideButton('weekly')" />
                  <label for="weekly">
                    <div class="switch" attr.data-unchecked="{{ 'search/notification/weekly' | lowercase | translate }}"
                      attr.data-checked="{{ 'search/notification/weekly' | lowercase | translate }}"></div>
                  </label>
                </div>
              </div>
            </div>
          </div> -->
          <div class="notification-form-Input-display notification-form-Input-padding24">
            <div class="notification-form-checkbox-paddingRight">
              <div [ngClass]="showCheckoxBorder && notificationForm.get('consent').value === false?'checkboxRed':'checkbox'">
                <mat-checkbox formControlName="consent" (keydown.enter)="onCheckboxToggle($event)"></mat-checkbox>
              </div>
            </div>
            <div class="notification-form-consent">
              <div [innerHTML]="'search/notification/consent1' | lowercase | translate">
              </div>
              <div class="notification-form-consent-padding">
              <span>{{'search/notification/consent2' | lowercase | translate }}</span>
              </div>
              <div>
                <a href="https://www.volvogroup.com/en/tools/privacy.html" target="new">{{ 'search/notification/privacyNotice' | lowercase | translate }}
                </a>
              </div>
            </div>
          </div>
          <div class="notification-notifyBtn" *ngIf="
              notificationForm.get('instantly').value === true ||
              notificationForm.get('weekly').value === true ||
              notificationForm.get('daily').value === true
            ">
            <button type="submit" [disabled]="btnstate" [ngClass]="btnstate?'notification-notifyBtnColor':''">
              {{ 'search/notification/notifyMe' | lowercase | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="submitted">
  <div class="notificationSuccess">
    <div class="notificationSuccess-success">
      {{ 'search/notification/success' | lowercase | translate }}
    </div>
    <div class="notificationSuccess-padding">
      <div class="notificationSuccess-text notificationSuccess-text-padding">
        {{ 'search/notification/success/filter' | lowercase | translate }}
      </div>
      <div class="notificationSuccess-details">
        <volvo-search-chip [fromNotification]="true"></volvo-search-chip>
      </div>
    </div>
    <div class="notificationSuccess-display notificationSuccess-padding">
      <div class="notificationSuccess-text">
        {{'search/notification/success/contactMethod' | lowercase | translate }}
      </div>
      <div class="notificationSuccess-details" *ngIf="notificationForm.get('email').value !== ''">
        {{ notificationForm.get('email').value }}
      </div>
    </div>
    <div *ngIf=" notificationForm.get('name').value !== '' || notificationForm.get('phone').value !== '' || notificationForm.get('company').value !== '' || notificationForm.get('country').value !== ''">
      <div *ngIf="notificationForm.get('name').value !== ''" class="notificationSuccess-display notificationSuccess-padding">
        <div class="notificationSuccess-text">
          {{ 'cookie/table/head/tdb' | lowercase | translate }}:
                  </div>
        <div class="notificationSuccess-details">
          {{ notificationForm.get('name').value }}
        </div>
      </div>
      <div *ngIf="notificationForm.get('phone').value !== ''" class="notificationSuccess-display notificationSuccess-padding" >
        <div class="notificationSuccess-text">
          {{ 'form/phoneNumber' | lowercase | translate }}:
        </div>
        <div class="notificationSuccess-details">
          {{ notificationForm.get('phone').value }}
        </div>
      </div>
      <div *ngIf="notificationForm.get('country').value !== ''" class="notificationSuccess-display notificationSuccess-padding">
        <div class="notificationSuccess-text">
          {{ 'form/country'  | lowercase | translate }}:
        </div>
        <div class="notificationSuccess-details">
          {{ notificationForm.get('country').value }}
        </div>
      </div>
      <div *ngIf="notificationForm.get('company').value !== ''" class="notificationSuccess-display notificationSuccess-padding">
        <div class="notificationSuccess-text">
          {{ 'form/company' | lowercase | translate }}:
        </div>
        <div class="notificationSuccess-details">
          {{ notificationForm.get('company').value }}
        </div>
      </div>
      <div *ngIf="notificationForm.get('postcode').value !== ''" class="notificationSuccess-display">
        <div class="notificationSuccess-text">
          {{'uk/search/notification/postcode' | lowercase | translate }}:
        </div>
        <div class="notificationSuccess-details">
          {{ notificationForm.get('postcode').value }}
        </div>
      </div>
    </div>
    <!-- <div class="notificationSuccess-display">
    <div class="notificationSuccess-text">
      {{ 'search/notification/success/often' | lowercase | translate }}
    </div>
    <div>
      <div class="notificationSuccess-details" *ngIf="notificationForm.get('instantly').value === true">
        {{ 'search/notification/instant' | lowercase | translate }}
      </div>
      <div class="notificationSuccess-details" *ngIf="notificationForm.get('weekly').value === true">
        {{ 'search/notification/weekly' | lowercase | translate }}
      </div>
      <div class="notificationSuccess-details" *ngIf="notificationForm.get('daily').value === true">
        {{ 'search/notification/daily' | lowercase | translate }}
      </div>
    </div>
  </div> -->
  </div>
  <div class="notificationSuccess-footerText">
    {{ 'search/notification/success/notified' | lowercase | translate }}
  </div>
  <div style="background: white;">
    <button (click)="onClose()" class="notificationSuccess-button" #okButton>
      OK
    </button>
  </div>
</div>
</div>
<ng-template #others>
  <div class="notification" *ngIf="!submitted">
    <div class="notification-mainHeader">
        <img [src]="assetService.getAssetUrl('assets/icons/bell.svg')" alt="{{ 'search/notification' | lowercase | translate }}"/>
      <span class="notification-mainHeader-headerText mobileFont">{{ 'search/notification' | lowercase | translate }}</span>
      <i (click)="onClose()" class="notification-mainHeader-closeBtn material-icons mobileBtnCss">close</i>
    </div>
    <div class="notification-Text notification-padding24 notification-textMargin">
      <div class="notification-Text-bold">
        {{'search/notification/noFilter/text1'| lowercase | translate}}
      </div>
      <div class="notification-padding16">
          {{'search/notification/noFilter/text2' | lowercase | translate}}
      </div>
    </div>
  </div>
</ng-template>
