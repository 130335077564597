<section class="Cookies">
  <div class="Cookies-wp">
    <div [innerHTML]="'cookie/sectionA/title' | lowercase | translate" class="Cookies-mainTitle"></div>
    <!-- <div>
      <a class="Cookies-edit-link" (click)="editCookiesClick()">{{ 'cookie/sectionA/edit' | lowercase | translate
        }}.</a>
    </div> -->
    <div class="Cookies-text">
      {{ 'cookie/sectionA/text' | lowercase | translate }}
      <a [routerLink]="HtmlRoutePages.privacyPolicy | localize">{{ 'cookie/sectionA/here' | lowercase | translate
        }}</a>.
    </div>

    <div [innerHTML]="'cookie/sectionB' | lowercase | translate" class="Cookies-title"></div>

    <div [innerHTML]="'cookie/sectionC' | lowercase | translate" class="Cookies-text"></div>

    <div [innerHTML]="'cookie/sectionD' | lowercase | translate" class="Cookies-title"></div>
    <div [innerHTML]="'cookie/sectionE' | lowercase | translate" class="Cookies-text">:</div>

    <div class="Cookies-table">
      <!-- OneTrust Cookies List start -->
      <div id="ot-sdk-cookie-policy">

      </div>
      <!-- OneTrust Cookies List end -->

      <!-- <table class="ResTable">
        <thead class="ResTable-thead">
          <tr class="ResTable-tr">
            <th class="ResTable-th">{{ 'cookie/table/head/tdA' | lowercase | translate }}</th>
            <th class="ResTable-th">{{ 'cookie/table/head/tdB' | lowercase | translate }}</th>
            <th class="ResTable-th">{{ 'cookie/table/head/tdC' | lowercase | translate }}</th>
            <th class="ResTable-th">{{ 'cookie/table/head/tdD' | lowercase | translate }}</th>
            <th class="ResTable-th">{{ 'cookie/table/head/tdE' | lowercase | translate }}</th>
          </tr>
        </thead>
        <tbody class="ResTable-tbody">
          <tr class="ResTable-tr isTitle">
            <td class="ResTable-td">{{ 'cookie/table/itemA/title' | lowercase | translate }}</td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemA/tdA' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemA/tdB' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemA/tdC' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemA/tdD' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemA/tdE' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr isTitle">
            <td class="ResTable-td">{{ 'cookie/table/itemB/title' | lowercase | translate }}</td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdA' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdB' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdC' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdD' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdE' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdF' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdG' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdH' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdI' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemB/tdJ' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr isTitle">
            <td class="ResTable-td">{{ 'cookie/table/itemC/title' | lowercase | translate }}</td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdA' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdB' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdC' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdD' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdE' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdF' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdG' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdH' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdI' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemC/tdJ' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr isTitle">
            <td class="ResTable-td">{{ 'cookie/table/itemD/title' | lowercase | translate }}</td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdA' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdB' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdC' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdD' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdE' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdF' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdG' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdH' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdI' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemD/tdJ' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr isTitle">
            <td class="ResTable-td">{{ 'cookie/table/itemE/title' | lowercase | translate }}</td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdA' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdB' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdC' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdD' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdE' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdF' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdG' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdH' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdI' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdJ' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdK' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdL' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdM' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdN' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemE/tdO' | lowercase | translate }}</td>
          </tr>
          <tr class="ResTable-tr isTitle">
            <td class="ResTable-td">{{ 'cookie/table/itemF/title' | lowercase | translate }}</td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
            <td class="ResTable-td"></td>
          </tr>
          <tr class="ResTable-tr">
            <td class="ResTable-td">{{ 'cookie/table/itemF/tdA' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemF/tdB' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemF/tdC' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemF/tdD' | lowercase | translate }}</td>
            <td class="ResTable-td">{{ 'cookie/table/itemF/tdE' | lowercase | translate }}</td>
          </tr>
        </tbody>
      </table> -->
    </div>

    <div [innerHTML]="'cookie/sectionF/title' | lowercase | translate" class="Cookies-title"></div>
    <div [innerHTML]="'cookie/sectionF/text' | lowercase | translate" class="Cookies-text"></div>
    <!-- OneTrust Cookies Settings button start -->
<button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
<!-- OneTrust Cookies Settings button end -->
  </div>
</section>


<volvo-footer></volvo-footer>

<!-- <button (click)="editCookiesClick()" mat-fab color="primary" class="Cookies-edit">
  <i class="material-icons">settings</i>
</button> -->
