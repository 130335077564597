import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { AssetService } from '~/core/services/assets/asset.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'volvo-what-customer-say',
  templateUrl: './what-customer-say.component.html',
  styleUrls: ['../who-we-are/who-we-are.component.scss']
})
export class WhatCustomerSayComponent implements OnInit {
  videoUrl: SafeResourceUrl;
  isIframeLoaded: boolean = false;
  constructor(private translateService: TranslateService,private metaTagService: Meta,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService, private sanitizer: DomSanitizer
  ) { 
    // Sanitize the YouTube video URL
    const youtubeUrl = 'https://www.youtube.com/embed/LJCWRRe_Cks';
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeUrl);
  }

  ngOnInit(): void {
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/aboutus/whatcustomersay/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/aboutus/whatcustomersay/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/aboutus/whatcustomersay/altImage'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('About Us' , 'What our customer say', HtmlRoutePages.aboutUs);
  }

  onIframeLoad() {
    this.isIframeLoaded = true;
  }

}
