import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, SecurityContext } from '@angular/core';
import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { UntypedFormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { DealerPlaceResult } from '~/core/store/dealerships/dealerships.model';
import { HtmlRoutePages } from '~/app-routing.model';
import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalesStateLang } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { environment } from 'src/environments/environment';
import { AssetService } from '~/core/services/assets/asset.service';
import { InputValidator } from '~/popups/inputValidator';
import { CounterService } from '~/core/services/counter/counter-service';

@Component({
    selector: 'volvo-contact-dealership-popup',
    templateUrl: './contact-dealership-popup.component.html',
    styleUrls: ['./contact-dealership-popup.component.scss']
})
export class ContactDealershipPopupComponent implements OnInit {

    dealership: DealershipsService;
    @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
    // Add special characters to fit the selected language
    noSpecialCharRegEx = /^[\p{L}\- \.\?!'\r\n,0-9\(\)]+$/u;

    HtmlRoutePages = HtmlRoutePages;
    dealershipLocations: DealerPlaceResult[] = null;
    private currentLang: String;
    checkbox: string;
    reCaptchaRes: string;
    showRecaptcha: boolean = false;
    recaptchaToken: string;
    dealershipCounty: string;
    dealershipCountyId: string;
    dealershipCountyName: string;
    public dealershipCountries: { label: string, id: string }[] = null;
    form = this.fb.group({
        subject: ['', [Validators.required]],
        fullName: ['', [Validators.required, Validators.pattern(this.noSpecialCharRegEx), InputValidator.noWhitespace]],
        phone: ['', [Validators.required, Validators.pattern(/^\+?\d+$/)]],
        email: ['', [Validators.email, Validators.required]],
        company: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
        message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
        token: [''],
        subscribe: [false],
        privacy: [false, Validators.pattern('true')]
    });

    name: string = '';
    constructor(
        private fb: UntypedFormBuilder,
        private dealershipsService: DealershipsService,
        private mailApiService: MailApiService,
        private sanitizer: DomSanitizer,
        public localesService: LocalesService,
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<ContactDealershipPopupComponent>,
        public assetService: AssetService,
        private counterService: CounterService,
    ) {
        this.dealership = data;
        this.dealershipCountyName = data.name;
        this.dealershipCounty = data.stockLocation.country;
        this.recaptchaToken = environment.recaptchaSitekey;
    }

    ngOnInit(): void {
        this.showRecaptcha = false;

        this.dealershipCountries = [
            { label: "Austria", id: "at" },
            { label: "Belgium", id: "be-fr" },
            { label: "Bulgaria", id: "bg" },
            { label: "Croatia", id: "hr" },
            { label: "Czech Republic", id: "cz" },
            { label: "Denmark", id: "dk" },
            { label: "Finland", id: "fi" },
            { label: "France", id: "fr" },
            { label: "Germany", id: "de" },
            { label: "Hungary", id: "hu" },
            { label: "Italy", id: "it" },
            { label: "Lithuania", id: "lt" },
            { label: "Netherlands", id: "nl" },
            { label: "Norway", id: "no" },
            { label: "Poland", id: "pl" },
            { label: "Serbia", id: "rs" },
            { label: "Slovenia", id: "si" },
            { label: "Spain", id: "es" },
            { label: "Sweden", id: "se" },
            { label: "Bosnia and Herzegovina", id: "ba" },
            { label: "Romania", id: "ro" },
            { label: "Lithuania", id: "lt" },
            { label: "Slovakia", id: "sk" },
            { label: "Macedonia", id: "mk" },
            { label: "Estonia", id: "ee" },
            { label: "Switzerland", id: "ch-de" },
            { label: "Latvia", id: "lv" },
            { label: "South Africa", id: "za" }
        ]

        this.localesService.lang$
            .subscribe((lang: LocalesStateLang) => {
                this.currentLang = lang.curLang.id;
                this.checkbox = '/' + this.currentLang + '/privacy-policy';
            });
    }

    resolved(captchaResponse: string, res) {
        this.reCaptchaRes = captchaResponse;
    }
    recaptcha() {
        this.showRecaptcha = !this.showRecaptcha;
    }


    async onSubmit(): Promise<void> {
        if (this.form.valid && this.reCaptchaRes) {
            const body = this.transformDataForServer(this.form.value);
            const status = await this.mailApiService.postMailContact(body);

            if(body.subject.toLowerCase() === 'financing service'){
                this.counterService.postGenericFinanceRequest('Services','Email', body.dealershipcountry, body.dealership);
            }

            if (status.success === true) this.formDirective.resetForm()
            this.showRecaptcha = false;
            this.reCaptchaRes = '';

            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({
                'event': 'FormsubmittedContactus'
            });
        }
    }

    private transformDataForServer(data) {
        // TODO: ask someone to make nice API for this
        let dealershipCountyId = this.dealershipCountries.filter((x) => x.label === this.dealershipCounty);

        return {
            dealershipcountry: dealershipCountyId[0].id,
            dealership: this.dealershipCountyName,
            email: data.email,
            subject: data.subject,
            name: this.sanitizer.sanitize(SecurityContext.NONE, data.fullName).trim(),
            phone: data.phone,
            message: this.sanitizer.sanitize(SecurityContext.NONE, data.message).trim(),
            token: this.reCaptchaRes

        };
    }

    onClose(): void {
        this.dialogRef.close();
    }

    onCheckboxToggle(event: KeyboardEvent): void {
        const checkbox = event.target as HTMLElement;
        checkbox.click(); // Simulates a click to toggle the checkbox state
        this.recaptcha();
    }
}
