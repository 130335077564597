export const environment = {
    development: false,
    qa: true,
    production: false,
    name: 'qa',
    domain: 'localhost',
    apiUrl: 'https://selected-managed.azurewebsites.net/api/proxy/volvoselected/qa/api/',
    signalrUrl: '/volvoselected/qa/api/',
    cdnUrl: 'https://volvoselectedqa.blob.core.windows.net/',
    tokenProxyUrl: 'https://tokenproxy-managed.azurewebsites.net/api/TokenProxyV2Function',
    recaptchaSitekey: '6Leecr8qAAAAAITOLnYo9JXBiM71-JgiBRCJTHJ_',

    ukSiteUrl: 'qa.volvousedtrucks.co.uk',
    txSiteUrl: 'qa.tradeandexport.volvotrucks.com',
    txGreenSiteUrl: 'qa.tradeandexport.volvotrucks.com',
    selectedSiteUrl: 'qa.volvoselected.com',
    redirectUrl: 'https://qa.volvoselected.com',

    ukwebsite: "https://qa.volvousedtrucks.co.uk",
    txwebsite: "https://qa.tradeandexport.volvotrucks.com",
};