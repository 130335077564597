import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Router } from '@angular/router';
import { HtmlRoutePages, LangRoutePages } from '~/app-routing.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { Subscription } from 'rxjs';
import { regionEnum } from '~/core/declaration/declaration';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { ScreenUtils } from 'src/app/core/utils/screen-utils/screen-utils';
import { CountrySettingsStateInterface } from '~/core/store/regionalSettings/regionalSettings.model';
import { CounterService } from '~/core/services/counter/counter-service';
import { LocalesStateCountry } from '~/core/store/locales/locales.model';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { AssetService } from '~/core/services/assets/asset.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

export interface SubMenu {
  label: string;
  path?: string;
  navigateToOtherSite?: boolean;
  scrollToId?: string;
  name?: string;
  imagepath?: string;
}

export interface MenuItem {
  label: string;
  path?: string;
  submenu?: SubMenu[];
}

@AutoUnsubscribe()
@Component({
  selector: 'volvo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit, OnInit, OnDestroy {
  menu: MenuItem[];
  HtmlRoutePages = HtmlRoutePages;
  remainingWarranty = 'remainingWarranty';
  volvoSelected = 'volvoSelected';
  volvoApproved = 'volvoApproved';
  volvoEconomy = 'volvoEconomy';
  serviceContract = 'serviceContract';
  customisation = 'customisation';
  financing = 'financing';
  contact = 'centreNavLinks';
  volvoConnect = 'volvoConnect';
  region: string;
  regionEnum = regionEnum;
  isInitialized = false;
  isTablet: boolean;
  currentYear: number;
  hideFinancingForBiH: boolean = false;
  private countrySetting: string;
  cookieMenuItems = HtmlRoutePages.cookies;
  countriesWithoutPro: string[] = ['DK'];
  countriesWithoutAdvanced: string[] = ['DK'];

  s1: Subscription;
  volvoSelectedProEnabled: boolean;
  volvoSelectedAdvancedEnabled: boolean;
  site: string;
  isSelectedSite: boolean = false;
  regionalSettings: RegionSettings;
  environment = environment;

  truckRange = [
    { make: 'Volvo', model: 'FH16', path: HtmlRoutePages.volvoFH16 },
    { make: 'Volvo', model: 'FH', path: HtmlRoutePages.volvoFH },
    { make: 'Volvo', model: 'FM', path: HtmlRoutePages.volvoFM },
    { make: 'Volvo', model: 'FMX', path: HtmlRoutePages.volvoFMX },
    { make: 'Volvo', model: 'FE', path: HtmlRoutePages.volvoFE },
    { make: 'Volvo', model: 'FL', path: HtmlRoutePages.volvoFL }
  ];
  country: any;

  constructor(
    private router: Router,
    private regionalSettingsService: RegionalSettingsService,
    private globalUtils: GlobalUtils,
    private localesService: LocalesService,
    private counterService: CounterService,
    public store: Store,
    public assetService: AssetService,
    private msalService: MsalService,
    private localizeRouterService: LocalizeRouterService,

  ) {
    this.currentYear = new Date().getFullYear();

    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.country = store.selectSnapshot<string>((state: AppState) => state.language.country.curCountry.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);

    if (this.regionalSettings) {
      this.region = this.regionalSettings?.region;
      if (this.regionalSettings?.site === "volvoselected") {
        this.isSelectedSite = true;
      }
    }

    if (this.globalUtils.isBrowser() && ScreenUtils.isTablet()) {
      this.isTablet = true;
    }

    this.initMenu(this.volvoSelectedProEnabled, this.volvoSelectedAdvancedEnabled);

    this.initSubscriptions();
  }

  loginRequest = {
    scopes: ["api://d2c8eedd-7cc4-42a5-a6fc-78eb26466559/.default/Login"]
  };

  loginAndNavigate(event: Event): void {
    event.preventDefault();
    this.msalService.instance.loginPopup().then(() => {
      this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.admin)]);
    });
  }

  logout(event: Event): void {
    event.preventDefault();
    this.msalService.instance.logoutPopup().then(() => {
      this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.home)]);
    });
  }

  isLoggedIn(): boolean {
    const acc = this.msalService.instance.getActiveAccount()
    const res = acc != null;
    // console.log({ LoggedId: res });
    return res;
  }

  getUserName(): string {
    const account = this.msalService.instance.getActiveAccount();
    return account ? account.username : 'Guest';
  }

  initSubscriptions() {
    this.localesService.country$.subscribe((country: LocalesStateCountry) => {
      if (country && country.curCountry) {
        this.countrySetting = country.curCountry.id;
        this.hideFinancingForBiH = this.countrySetting === 'ba';
        this.s1 = this.regionalSettingsService.countrySettings$.subscribe((settings: CountrySettingsStateInterface) => {
          if (settings) {
            const currentCountrySettings = settings.countrysSettings.find(c => c.country === country.curCountry.id);
            this.volvoSelectedProEnabled = currentCountrySettings ? currentCountrySettings.selectedProEnabled : true;
            this.volvoSelectedAdvancedEnabled = currentCountrySettings ? currentCountrySettings.selectedAdvancedEnabled : true;
            this.initMenu(this.volvoSelectedProEnabled, this.volvoSelectedAdvancedEnabled);
          }
        });
      } else {
        this.countrySetting = null;
      }
    });
  }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    if (this.globalUtils.isBrowser() && ScreenUtils.isTablet()) {
      this.isTablet = true;
    }
  }

  ngOnDestroy(): void { }

  scroll(id: string) {
    const el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  logTruckModelButtonClick(model: string) {
    if (model) {
      this.counterService.postTruckModelClick(model);
    }
  }

  initMenu(volvoSelectedProEnabled: boolean, volvoSelectedAdvancedEnabled: boolean): void {
    const selectedBuyersGuideSubMenu: SubMenu[] = [
      { label: 'pages/truckModel/volvoFH16', path: HtmlRoutePages.volvoFH16, name: 'Volvo FH16' },
      { label: 'pages/truckModel/volvoFH', path: HtmlRoutePages.volvoFH, name: 'Volvo FH' },
      { label: 'pages/truckModel/volvoFM', path: HtmlRoutePages.volvoFM, name: 'Volvo FM' },
      { label: 'pages/truckModel/volvoFMX', path: HtmlRoutePages.volvoFMX, name: 'Volvo FMX' },
      { label: 'pages/truckModel/volvoFE', path: 'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fe.html', navigateToOtherSite: true },
      { label: 'pages/truckModel/volvoFL', path: 'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fl.html', navigateToOtherSite: true },
    ];

    const buyersGuideSubMenu: SubMenu[] = [
      { label: 'uk/pages/buyersguide/volvoFH16', path: HtmlRoutePages.bgvolvoFH16, name: 'Volvo FH16' },
      { label: 'uk/pages/buyersguide/volvoFH', path: HtmlRoutePages.bgvolvoFH, name: 'Volvo FH' },
      { label: 'uk/pages/buyersguide/volvoFM', path: HtmlRoutePages.bgvolvoFM, name: 'Volvo FM' },
      { label: 'uk/pages/buyersguide/volvoFMX', path: HtmlRoutePages.bgvolvoFMX, name: 'Volvo FMX' },
      { label: 'uk/pages/buyersguide/volvoFE', path: this.isSelectedSite ? 'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fe.html' : HtmlRoutePages.bgvolvoFE, name: 'Volvo FE' },
      { label: 'uk/pages/buyersguide/volvoFL', path: this.isSelectedSite ? 'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fl.html' : HtmlRoutePages.bgvolvoFL, name: 'Volvo FL' }
    ];


    const vehicleCategoriesSubMenu: SubMenu[] = [
      { label: 'uk/components/footer/remainingWarranty', path: this.isTablet ? HtmlRoutePages.vehicleWarranty : HtmlRoutePages.vehicleOffers, scrollToId: this.isTablet ? '' : LangRoutePages.vehicleWarranty },
      { label: 'uk/components/footer/volvoSelected', path: this.isTablet ? HtmlRoutePages.vehicleSelected : HtmlRoutePages.vehicleOffers, scrollToId: this.isTablet ? '' : LangRoutePages.vehicleSelected },
      { label: 'uk/components/footer/volvoApproved', path: this.isTablet ? HtmlRoutePages.vehicleApproved : HtmlRoutePages.vehicleOffers, scrollToId: this.isTablet ? '' : LangRoutePages.vehicleApproved },
      { label: 'uk/components/footer/volvoEconomy', path: this.isTablet ? HtmlRoutePages.vehicleEconomy : HtmlRoutePages.vehicleOffers, scrollToId: this.isTablet ? '' : LangRoutePages.vehicleEconomy },
      { label: 'uk/components/footer/tradeExport', path: HtmlRoutePages.tradeExport },
    ];

    const serviceSubMenu: SubMenu[] = [
      { label: 'uk/pages/services/volvoSelected', path: this.isTablet ? HtmlRoutePages.serviceApproved : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.serviceApproved },
      { label: 'uk/pages/services/volvoEconomy', path: this.isTablet ? HtmlRoutePages.serviceEconomy : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.serviceEconomy },
      { label: 'uk/components/footer/financing', path: this.isTablet ? HtmlRoutePages.finance : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.finance },
      { label: 'uk/components/footer/serviceContracts', path: this.isTablet ? HtmlRoutePages.serviceContract : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.serviceContract },
      { label: 'uk/components/footer/customisation', path: this.isTablet ? HtmlRoutePages.customisation : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.customisation },
      { label: 'uk/components/footer/volvoconnect', path: this.isTablet ? HtmlRoutePages.volvoConnect : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.volvoConnect },
    ];

    const txServiceSubMenu: SubMenu[] = [
      { label: 'pages/services/export', path: this.isTablet ? HtmlRoutePages.serviceExport : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.serviceExport },
      { label: 'pages/services/utcustomisation', path: this.isTablet ? HtmlRoutePages.serviceCustomisation : HtmlRoutePages.services, scrollToId: this.isTablet ? '' : LangRoutePages.serviceCustomisation },
    ];

    const selectedServiceSubMenu: SubMenu[] = [
      (volvoSelectedProEnabled ? { label: 'home/sectionc/itemc/title', path: HtmlRoutePages.volvoSelected, scrollToId: 'selectedPro' } : null),
      (volvoSelectedAdvancedEnabled ? { label: 'pages/services/volvoselected/advanced', path: HtmlRoutePages.volvoSelected, scrollToId: 'selectedAdvance' } : null),
      { label: 'aboutus/sectione/title', path: HtmlRoutePages.volvoSelected, scrollToId: 'additionalservices' },
      (this.hideFinancingForBiH ? null : { label: 'pages/financing', path: HtmlRoutePages.financing }),
    ];

    const socialMediaSubMenu: SubMenu[] = [
      { label: 'components/footer/facebook', path: this.region === regionEnum.UK ? 'https://www.facebook.com/VolvoUsedTrucks/' : this.isSelectedSite ? 'https://www.facebook.com/VolvoTrucks' : 'https://www.facebook.com/VTBCNorthandScotland', navigateToOtherSite: true, imagepath: '/assets/icons/facebook.svg' },
      { label: 'components/footer/youtube', path: this.isSelectedSite ? 'https://www.youtube.com/user/VolvoTrucks' : 'https://www.youtube.com/volvotrucksuk', navigateToOtherSite: true, imagepath: '/assets/icons/youtube.svg' },
      (this.isSelectedSite ? (this.country === 'dk' ? { label: 'components/footer/instagram', path: 'https://www.instagram.com/volvotrucksdk/', navigateToOtherSite: true, imagepath: '/assets/icons/Instagram.svg' } : undefined) : { label: 'components/footer/instagram', path: 'http://www.instagram.com/volvotrucksuk', navigateToOtherSite: true, imagepath: '/assets/icons/Instagram.svg' }),
      (this.isSelectedSite ? undefined : { label: 'uk/components/footer/linkedIn', path: 'https://www.linkedin.com/company/volvo-trucks-uk/', navigateToOtherSite: true, imagepath: '/assets/icons/linkedIn.svg' }),
    ];

    const aboutUsSubMenu: SubMenu[] = [
      (this.isSelectedSite ? { label: 'components/footer/aboutus/whybuyusedtruck', path: HtmlRoutePages.aboutUs, scrollToId: 'whyBuyUsedTruck' } : { label: 'uk/pages/aboutus/whoweare', path: this.isTablet ? HtmlRoutePages.whoweare : HtmlRoutePages.aboutUs, scrollToId: this.isTablet ? '' : LangRoutePages.whoweare })
    ];

    const contactUsSubMenu: SubMenu[] = [
      { label: 'components/footer/contactus/contactform', path: HtmlRoutePages.contact },
      { label: 'components/footer/contactus/dealearshiplocation', path: HtmlRoutePages.contact, scrollToId: 'ourDealerships' }
    ];
    const helpSubMenu: SubMenu[] = [
      { label: 'uk/components/footer/faq', path: HtmlRoutePages.faq },
      { label: 'uk/components/footer/contactSalesTeam', path: HtmlRoutePages.contact },
      { label: 'uk/components/footer/findDealership', path: this.isTablet ? HtmlRoutePages.ourCentres : HtmlRoutePages.contact, scrollToId: this.isTablet ? '' : 'contact' },
    ];
    const helpSubMenuTX: SubMenu[] = [
      { label: 'uk/components/footer/faq', path: HtmlRoutePages.faq },
      { label: 'uk/components/footer/contactSalesTeam', path: HtmlRoutePages.contact }
    ];

    if (this.isSelectedSite) {
      this.menu = [
        { label: 'components/footer/service', submenu: this.isSelectedSite ? selectedServiceSubMenu : serviceSubMenu },
        { label: 'components/footer/truckrange', submenu: selectedBuyersGuideSubMenu },
        { label: 'pages/aboutUs', submenu: aboutUsSubMenu },
        { label: 'components/footer/socialmedia', submenu: socialMediaSubMenu },
        { label: 'pages/contact', submenu: contactUsSubMenu },
      ];
    }
    else {
      if (this.region === "TX") {
        this.menu = [
          { label: 'uk/pages/buyersguide', submenu: buyersGuideSubMenu },
          // { label: 'uk/components/footer/vehicleCategories', submenu: vehicleCategoriesSubMenu },
          { label: 'components/footer/service', submenu: this.isSelectedSite ? selectedServiceSubMenu : txServiceSubMenu },
          { label: 'pages/aboutUs', submenu: aboutUsSubMenu },
          { label: 'uk/components/footer/help', submenu: helpSubMenuTX },
        ];
      }
      else {
        this.menu = [
          { label: 'uk/pages/buyersguide', submenu: buyersGuideSubMenu },
          { label: 'uk/components/footer/vehicleCategories', submenu: vehicleCategoriesSubMenu },
          { label: 'components/footer/service', submenu: this.isSelectedSite ? selectedServiceSubMenu : serviceSubMenu },
          { label: 'uk/components/footer/socialMedia', submenu: socialMediaSubMenu },
          { label: 'uk/components/footer/help', submenu: helpSubMenu },
        ];
      }
    }

    if (this.isTablet) {
      this.menu = this.menu.filter(item => item.label !== 'uk/pages/buyersguide');
    }

    // Remove all null values from the menu recursively
    this.menu = this.filterNullValues(this.menu);

    this.isInitialized = true;
  }

  private filterNullValues(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.reduce<MenuItem[]>((acc, item) => {
      if (item) {
        const newItem: MenuItem = { ...item };
        if (newItem.submenu) {
          newItem.submenu = this.filterNullValues(newItem.submenu);
          if (newItem.submenu.length > 0) {
            acc.push(newItem);
          }
        } else {
          acc.push(newItem);
        }
      }
      return acc;
    }, []);
  }
}
