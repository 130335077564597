import { Component, OnDestroy, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { DealerPlaceResult } from '~/core/store/dealerships/dealerships.model';
import { HtmlRoutePages } from '~/app-routing.model';
import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalesStateLang } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { environment } from 'src/environments/environment';
import { MatSelectionList } from '@angular/material/list';
import { SearchState } from '~/core/store/search/search.state';
import { LocalesStateCountryList } from '../../../core/store/locales/locales.model';
import { countries } from '../../../core/constants/country';
import { CounterService } from '~/core/services/counter/counter-service';
@AutoUnsubscribe()
@Component({
    selector: 'volvo-contact-page-form',
    templateUrl: './contact-page-form.component.html',
    styleUrls: ['./contact-page-form.component.scss']
})
export class ContactPageFormComponent implements OnInit, OnDestroy {

    @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

    // Add special characters to fit the selected language
    noSpecialCharRegEx = /^[\p{L}\- \.\?!'\r\n,0-9\(\)]+$/u;

    HtmlRoutePages = HtmlRoutePages;
    dealershipLocations: DealerPlaceResult[] = null;
    form = this.fb.group({
        subject: ['', [Validators.required]],
        fullName: ['', [Validators.required]],
        phone: ['', [Validators.pattern(/\d/g), Validators.required]],
        email: ['', [Validators.email, Validators.required]],
        company: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
        location: ['', [Validators.required]],
        city: [''],
        message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
        token: [''],
        subscribe: [false],
        privacy: [false, Validators.pattern('true')]
    });


    dealershipCountries=countries;
    public selectedCountry: {} = null;
    public showCities: {} = null;
    autoSelectCountry:LocalesStateCountryList;

    private dealershipCities = [
        { id: "lt", cities: ['Klaipeda', 'Kaunas', 'Vilnius', 'Šiauliai'] },
        { id: "cz", cities: ['Čestlice', 'Olomouc'] },
        { id: "dk", cities: ['Taastrup', 'Aalborg', 'Aarhus', 'Holstebro', 'Randers', 'Viborg'] },
        { id: "fr", cities: ['Nord - Saint Quentin', 'Sud Est - Marseille Nord', 'Ouest -Saint Brieuc', 'Ouest - Loudeac', 'Sud Est - Valence Sud', 'Ouest -Quimper', 'Ouest - Landivisiau', 'Sud Ouest - Toulouse', 'Sud Ouest - Bordeaux', 'Ouest - Rennes', 'Ouest - Saint malo', 'Sud Est -Grenoble', 'Sud Est -Saint Etienne', 'Ouest - Carquefou', 'Ouest - Nantes', 'Nord - Reims', 'Ouest -Lorient', 'Ouest - Vannes', 'Nord - Somain', 'Lesquin', 'Nord - Compiegne', 'Nord -Arras', 'Nord - Arques', 'Bethune', 'Sud Ouest - Perpignan', 'Sud Est - Lyon', 'Sud Est - Chambery', 'Nord -Rouen', 'Le Havre', 'Sud Est - Brignoles', 'Sud Est -Cavaillon', 'Sud Est - Avignon', 'Nord Massy', 'Nord Roissy'] },
        { id: "es", cities: ['Bilbao', 'Coruña', 'Oviedo', 'Pontevedra', 'Santander', 'Zaragoza', 'Manzanares', 'Salamanca', 'Torrejón', 'Valdemoro'] },
        { id: "no", cities: ['Furuset', 'Drammen', 'Hønefoss', 'Gjøvik', 'Lillehammer', 'Gardermoen'] },
        { id: "it", cities: ['Volvo Truck Center Venezia', 'Volvo Truck Center Zingonia'] },
        { id: "fi", cities: ['Volvo Truck Center Vantaa', 'Volvo Truck Center Raasepori', 'Volvo Truck Center Porvoo', 'Volvo Truck Center Riihimäki (HF-Autohuolto Oy)', 'Volvo Truck Center Tampere', 'Volvo Truck Center Lahti', 'Volvo Truck Center Jyväskylä', 'Volvo Truck Center Kuopio', 'Volvo Truck Center Joensuu (Raskaspari Oy)', 'Volvo Truck Center Turku', 'Volvo Truck Center Pori', 'Volvo Truck Center Forssa'] },
        { id: "pl", cities: ['Volvo Truck Center Dąbrowa k/Opola', 'Volvo Truck Center Świętochłowice', 'Volvo Truck Center Skawina', 'Volvo Truck Center Młochów', 'Volvo Truck Center Długołęka', 'Volvo Truck Center Pruszcz Gdański', 'Volvo Truck Center Siedlce'] },
        { id: "hu", cities: ['Volvo Truck Center Budapest'] },
        { id: "sk", cities: ['Volvo Group Slovakia'] },
        { id: "si", cities: ['Volvo d.o.o.'] },
        { id: "at", cities: ['Volvo Group Tribuswinkel', 'Volvo Group Premstaetten'] },
        { id: "ro", cities: ['Volvo Romania Bolintin', 'Volvo Romania Oradea'] },
        { id: "bs", cities: ['Volvo d.o.o. Sarajevo'] },
        { id: "mk", cities: ['VOLVO Macedonia'] },
        { id: "rs", cities: ['Volvo doo Novi Banovci'] },
        { id: "hr", cities: ['Volvo Group Croatia d.o.o.'] },
        { id: "de", cities: ['Volvo Group Used Trucks Center Wiedemar', 'Volvo Group Used Trucks Center Kassel'] },
        { id: "be-nl", cities: ['Volvo Truck Center'] },
        { id: "be-fr", cities: ['Volvo Truck Center'] },
        { id: "nl", cities: ['Volvo Group Truck Center'] },
        { id: "bg", cities: ['Volvo Group Bulgaria EOOD'] },
        { id: "se", cities: ['Volvo Group Truck Center'] },
        { id: "ee", cities: ['Volvo Truck Center Tallinn'] },
        { id: "lv", cities: ['Volvo Truck Latvia SIA'] },
        { id: "ch-de", cities: ['Volvo Group (Schweiz) AG'] },
        { id: "ch-fr", cities: ['Volvo Group (Schweiz) AG'] },
        { id: "ch-it", cities: ['Volvo Group (Schweiz) AG'] },
        { id: "za", cities: ['Volvo Truck Center'] },
        { id: "ba", cities: ['Volvo Truck Center'] },
    ]

    private s0: Subscription;
    private s1: Subscription;
    public currentLang: String;
    checkbox: string;
    reCaptchaRes: string;
    showRecaptcha: boolean = false;
    recaptchaToken: string;
    region: string;

    constructor(
        private fb: UntypedFormBuilder,
        private dealershipsService: DealershipsService,
        private mailApiService: MailApiService,
        private sanitizer: DomSanitizer,
        public localesService: LocalesService,
        private searchState: SearchState,
        private counterService: CounterService,
    ) {
        this.initSubscriptions();
        this.recaptchaToken = environment.recaptchaSitekey;
    }

    ngOnInit(): void {
        this.showRecaptcha = false;
        // this.dealershipCountries = [
        //     { label: "Czech Republic", id: "cz" },
        //     { label: "Danmark", id: "dk" },
        //     { label: "Lietuva", id: "lt" },
        //     { label: "France", id: "fr" },
        //     { label: "España", id: "es" },
        //     { label: "Norsk", id: "no" },
        //     { label: "Italia", id: "it" },
        //     { label: "Suomi", id: "fi" },
        //     { label: "Polska", id: "pl" },
        //     { label: "Magyarország", id: "hu" },
        //     { label: "Slovensko", id: "sk" },
        //     { label: "Slovenija", id: "si" },
        //     { label: "Austria", id: "at" },
        //     { label: "România", id: "ro" },
        //     { label: "Bosna", id: "ba" },
        //     { label: "Makedonija", id: "mk" },
        //     { label: "Serbia", id: "rs" },
        //     { label: "Hrvatska", id: "hr" },
        //     { label: "Deutschland", id: "de" },
        //     { label: "België(NL)", id: "be-nl" },
        //     { label: "Belgique(FR)", id: "be-fr" },
        //     { label: "Nederland", id: "nl" },
        //     { label: "Bulgariya", id: "bg" },
        //     { label: "Sweden", id: "se" },
        //     { label: "Eesti", id: "ee" },
        //     { label: "Latvija", id: "lv" },
        //     { label: "Schweiz(DE)", id: "ch-de" },
        //     { label: "Suisse(FR)", id: "ch-fr" },
        //     { label: "South Africa", id: "za" }].sort((a, b) => {
        //         return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
        //     });
            
        if(this.autoSelectCountry.id !== null){
            this.showCities = this.dealershipCities.find(i => i.id === this.autoSelectCountry.id).cities;
        }

        this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
            this.currentLang = lang.curLang.id;
            this.checkbox = '/' + this.currentLang + '/privacy-policy';
        });
    }

    ngOnDestroy(): void {
    }

    resolved(captchaResponse: string, res) {
        this.reCaptchaRes = captchaResponse;
    }
    recaptcha() {
        this.showRecaptcha = !this.showRecaptcha;
    }


    async onSubmit(): Promise<void> {

        if (this.form.valid && this.reCaptchaRes) {

            const body = this.transformDataForServer(this.form.value);
            const status = await this.mailApiService.postMailContact(body);

            if (status.success === true) this.formDirective.resetForm()
            this.showRecaptcha = false;
            this.reCaptchaRes = '';

            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({
                'event': 'FormsubmittedContactus'
            });
            //logging generic finance request
            if(body.subject.toLowerCase() === 'financing service'){
                this.counterService.postGenericFinanceRequest('Contact us','Email', body.dealershipcountry, body.dealership);
            }
        }
    }

    private transformDataForServer(data) {
        const res = {
            name: this.sanitizer.sanitize(SecurityContext.NONE, data.fullName).trim(),
            phone: data.phone,
            email: data.email,
            dealershipcountry: data.location,
            dealership: data.city,
            userLang: this.currentLang,
            subject: data.subject,
            company: data.company,
            message: this.sanitizer.sanitize(SecurityContext.NONE, data.message).trim(),
            token: this.reCaptchaRes,
            region: this.region,
            site: "volvoselected"
        };
        return res;
    }

    onSelect(elem:any) {
        let selectCountry = this.dealershipCities.find(i => i.id === elem.value);
        this.showCities = selectCountry.cities;
    }

    private initSubscriptions(): void {
        this.s0 = this.dealershipsService.dealerships$
            .subscribe((dealershipLocations: DealerPlaceResult[]) => {
                if (dealershipLocations) {
                    this.dealershipLocations = dealershipLocations;
                }
            });

        this.s1 = this.localesService.region$
            .subscribe(region => {
                this.region = region.curRegion.id;
            });
            
        this.autoSelectCountry = this.searchState.getCurrentCountry();
    }

    onCheckboxToggle(event: KeyboardEvent): void {
        const checkbox = event.target as HTMLElement;
        checkbox.click(); // Simulates a click to toggle the checkbox state
        this.recaptcha();
    }
}
