import {
    LocalizeParser,
    LocalizeRouterModule,
    LocalizeRouterService,
    LocalizeRouterSettings,
    ManualParserLoader
} from '@gilsdav/ngx-translate-router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { Routes, RouterModule, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';

import { PrivacyPolicyPageComponent } from '~/pages/privacy-policy-page/privacy-policy-page.component';
// import { NotFoundPageComponent } from '~/pages/not-found-page/not-found-page.component';
import { AboutUsPageComponent } from '~/pages/about-us-page/about-us-page.component';
import { CookiesPageComponent } from '~/pages/cookies-page/cookies-page.component';
import { FinancePageComponent } from '../app/pages/services-page/finance-page/finance-page.component';
import { ContactPageComponent } from '~/pages/contact-page/contact-page.component';
import { StockPageComponent } from '~/pages/stock-page/stock-page.component';
import { TruckPageComponent } from '~/pages/truck-page/truck-page.component';
import { HomePageComponent } from '~/pages/home-page/home-page.component';
import { HtmlRoutePages, LangRoutePages } from '~/app-routing.model';
import { LocalesStateLangList } from '~/core/store/locales/locales.model';
import { MarketsPageComponent } from '~/pages/markets-page/markets-page.component';
import { environment } from 'src/environments/environment';
import { PrintLayoutComponent } from '~/pages/truck-page/print-layout/print-layout.component';
import { PrintTruckPageComponent } from '~/pages/truck-page/print-truck-page/print-truck-page.component';
import { UnsubscribePageComponent } from '~/pages/unsubscribe-page/unsubscribe-page.component'
import { ServicePageComponent } from '~/pages/services-page/service-page.component'
import { VolvoSelectedPageComponent } from '~/pages/services-page/volvo-selected-page/volvo-selected-page.component'
import { TruckModelPageComponent } from '~/pages/truck-model-page/truck-model-page.component'
import { VolvoFHPageComponent } from '~/pages/truck-model-page/volvoFH-page/volvoFH-page.component'
import { VolvoFH16PageComponent } from '~/pages/truck-model-page/volvoFH16-page/volvoFH16-page.component'
import { volvoFMPageComponent } from '~/pages/truck-model-page/volvoFM-page/volvoFM-page.component'
import { volvoFMXPageComponent } from '~/pages/truck-model-page/volvoFMX-page/volvoFMX-page.component'
import { WhoWeAreComponent } from '~/pages/about-us-page/who-we-are/who-we-are.component';
import { WhatWeHaveComponent } from '~/pages/about-us-page/what-we-have/what-we-have.component';
import { WhatCustomerSayComponent } from '~/pages/about-us-page/what-customer-say/what-customer-say.component';
import { CentreBanburyComponent } from '~/pages/contact-page/centre-banbury/centre-banbury.component';
import { CentreBardonComponent } from '~/pages/contact-page/centre-bardon/centre-bardon.component';
import { CentreElyComponent } from '~/pages/contact-page/centre-ely/centre-ely.component';
import { CentreEnfieldComponent } from '~/pages/contact-page/centre-enfield/centre-enfield.component';
import { CentreFelixstoweComponent } from '~/pages/contact-page/centre-felixstowe/centre-felixstowe.component';
import { CentreHayesComponent } from '~/pages/contact-page/centre-hayes/centre-hayes.component';
import { CentreReadingComponent } from '~/pages/contact-page/centre-reading/centre-reading.component';
import { CentreWellingboroughComponent } from '~/pages/contact-page/centre-wellingborough/centre-wellingborough.component';
import { CentreNavlinkComponent } from '~/pages/contact-page/centre-navlink/centre-navlink.component';
import { ContactDetailsComponent } from '~/pages/contact-page/contact-details/contact-details.component';
import { regionEnum } from '~/core/declaration/declaration';
import { VehicleOfferPageComponent } from '~/pages/vehicle-offer-page/vehicle-offer-page.component';
import { VehicleOfferRemainingWarrantyPageComponent } from 'src/app/pages/vehicle-offer-page/remaining-warranty/vehicle-offer-remaining-warranty-page.component'
import { VolvoVehicleOfferSelectedPageComponent } from 'src/app/pages/vehicle-offer-page/volvo-selected/vehicle-offer-volvo-selected-page.component'
import { VolvoVehicleOfferApprovedPageComponent } from 'src/app/pages/vehicle-offer-page/volvo-approved/vehicle-offer-volvo-approved-page.component'
import { VolvoVehicleOfferEconomyPageComponent } from 'src/app/pages/vehicle-offer-page/volvo-economy/vehicle-offer-volvo-economy-page.component'
import { BuyersGuidePageComponent } from '~/pages/buyers-guide-page/buyers-guide-page.component';
import { BGVolvoFEPageComponent } from '~/pages/buyers-guide-page/volvoFE-page/volvoFE-page.component';
import { BGVolvoFHPageComponent } from '~/pages/buyers-guide-page/volvoFH-page/volvoFH-page.component';
import { BGVolvoFH16PageComponent } from '~/pages/buyers-guide-page/volvoFH16-page/volvoFH16-page.component';
import { BGvolvoFLPageComponent } from '~/pages/buyers-guide-page/volvoFL-page/volvoFL-page.component';
import { BGvolvoFMPageComponent } from '~/pages/buyers-guide-page/volvoFM-page/volvoFM-page.component';
import { BGvolvoFMXPageComponent } from '~/pages/buyers-guide-page/volvoFMX-page/volvoFMX-page.component';
import { VolvoExportPageComponent } from '~/pages/services-page/volvo-export-page/volvo-export-page.component';
import { UTCustomisationPageComponent } from '~/pages/services-page/volvo-utCustomisation-page/utCustomisation-page.component';
import { VolvoApprovedServicePageComponent } from '~/pages/services-page/volvo-approved-page/volvo-approved-page.component';
import { VolvoSelectedFAQPageComponent } from '~/pages/services-page/volvo-approved-page/FAQ/faq-page.component';
import { VolvoServiceEconomyPageComponent } from '~/pages/services-page/volvo-economy-page/service-economy-page.component';
import { VolvoEconomyFAQPageComponent } from '~/pages/services-page/volvo-economy-page/FAQ/faq-economy-page.component';
import { TradeExportPageComponent } from '~/pages/trade-export/trade-export-page.component';
import { FaqComponent } from '~/pages/faq/faq.component';
import { CentreEdinburghComponent } from '~/pages/contact-page/centre-edinburgh/centre-edinburgh.component';
import { CentreGlasgowwestComponent } from '~/pages/contact-page/centre-glasgowwest/centre-glasgowwest.component';
import { CentreWashingtonComponent } from '~/pages/contact-page/centre-washington/centre-washington.component';

const LOCAL_URL = environment.apiUrl + 'Languages';
import { RegionalPopupComponent } from '~/popups/regional-popup/regional-popup.component';
import { FinancingPageComponent } from './pages/services-page/financing-page/financing-page.component';
import { ServiceContractPageComponent } from './pages/services-page/service-contract-page/service-contract-page.component';
import { CustomisationPageComponent } from './pages/services-page/customisation-page/customisation-page.component';
import { VolvoConnectPageComponent } from './pages/services-page/volvo-connect-page/volvo-connect-page.component';
import { allLangRegEx } from './core/constants/constants';
import { ComplaintPageComponent } from '~/pages/complaint-page/complaint-page.component';
import { AdminPageComponent } from '~/pages/admin-page/admin-page.component';

export interface RoutesPageData {
    title: string;
}

const routes: Routes = [
    {
        path: LangRoutePages.home,
        component: HomePageComponent,
        data: { title: '' }
    },
    {
        path: LangRoutePages.vehicleOffers,
        children: [
            {
                path: '',
                component: VehicleOfferPageComponent,
            },
            {
                path: LangRoutePages.vehicleWarranty,
                component: VehicleOfferRemainingWarrantyPageComponent,

            },
            {
                path: LangRoutePages.vehicleSelected,
                component: VolvoVehicleOfferSelectedPageComponent,

            },
            {
                path: LangRoutePages.vehicleApproved,
                component: VolvoVehicleOfferApprovedPageComponent,

            },
            {
                path: LangRoutePages.vehicleEconomy,
                component: VolvoVehicleOfferEconomyPageComponent,

            }
        ]
    },
    {
        path: LangRoutePages.buyersGuide,

        children: [
            {
                path: '',
                component: BuyersGuidePageComponent,
            },
            {
                path: LangRoutePages.bgvolvoFH16,
                component: BGVolvoFH16PageComponent,

            },
            {
                path: LangRoutePages.bgvolvoFH,
                component: BGVolvoFHPageComponent,

            },
            {
                path: LangRoutePages.bgvolvoFM,
                component: BGvolvoFMPageComponent,

            },
            {
                path: LangRoutePages.bgvolvoFMX,
                component: BGvolvoFMXPageComponent,

            },
            {
                path: LangRoutePages.bgvolvoFE,
                component: BGVolvoFEPageComponent,

            },
            {
                path: LangRoutePages.bgvolvoFL,
                component: BGvolvoFLPageComponent,

            },
        ]
    },
    {
        path: LangRoutePages.search,
        // canActivate: [
        //     MsalGuard
        // ],
        children: [
            {
                path: '',
                component: StockPageComponent,

                data: { isNotFound: false }
            },
            {
                path: LangRoutePages.truck,
                component: TruckPageComponent,

            }
        ]
    },
    {
        path: LangRoutePages.markets,
        component: MarketsPageComponent,
        data: { skipRouteLocalization: true }
    },
    {
        path: LangRoutePages.regions,
        component: RegionalPopupComponent,
        data: { skipRouteLocalization: true }
    },
    {
        path: LangRoutePages.contact,

        children: [
            {
                path: '',
                component: ContactPageComponent,
            },
            {
                path: LangRoutePages.contact,
                component: ContactPageComponent,
            },
            {
                path: LangRoutePages.centreBanbury,
                component: CentreBanburyComponent,

            },
            {
                path: LangRoutePages.centreBardon,
                component: CentreBardonComponent,

            },
            {
                path: LangRoutePages.centreEly,
                component: CentreElyComponent,

            },
            {
                path: LangRoutePages.centreEnfield,
                component: CentreEnfieldComponent,

            },
            {
                path: LangRoutePages.centreFelixStowe,
                component: CentreFelixstoweComponent,

            },
            {
                path: LangRoutePages.centreHayes,
                component: CentreHayesComponent,

            },
            {
                path: LangRoutePages.centreReading,
                component: CentreReadingComponent,


            },
            {
                path: LangRoutePages.centreWellingBorough,
                component: CentreWellingboroughComponent,

            },
            {
                path: LangRoutePages.centreEdinburgh,
                component: CentreEdinburghComponent,

            },
            {
                path: LangRoutePages.centreGlasgowWest,
                component: CentreGlasgowwestComponent,

            },
            {
                path: LangRoutePages.centreWashington,
                component: CentreWashingtonComponent,

            },
            {
                path: LangRoutePages.meetTeam,
                component: ContactDetailsComponent,

            },
            {
                path: LangRoutePages.ourCentres,

                children: [
                    {
                        path: '',
                        component: CentreNavlinkComponent,
                    },
                    {
                        path: LangRoutePages.centreBanbury,
                        component: CentreBanburyComponent,

                    },
                    {
                        path: LangRoutePages.centreBardon,
                        component: CentreBardonComponent,

                    },
                    {
                        path: LangRoutePages.centreEly,
                        component: CentreElyComponent,

                    },
                    {
                        path: LangRoutePages.centreEnfield,
                        component: CentreEnfieldComponent,

                    },
                    {
                        path: LangRoutePages.centreFelixStowe,
                        component: CentreFelixstoweComponent,

                    },
                    {
                        path: LangRoutePages.centreHayes,
                        component: CentreHayesComponent,

                    },
                    {
                        path: LangRoutePages.centreReading,
                        component: CentreReadingComponent,


                    },
                    {
                        path: LangRoutePages.centreWellingBorough,
                        component: CentreWellingboroughComponent,

                    },
                    {
                        path: LangRoutePages.centreEdinburgh,
                        component: CentreEdinburghComponent,

                    },
                    {
                        path: LangRoutePages.centreGlasgowWest,
                        component: CentreGlasgowwestComponent,

                    },
                    {
                        path: LangRoutePages.centreWashington,
                        component: CentreWashingtonComponent,

                    },
                ]
            },

        ]
    },
    {
        path: LangRoutePages.faq,
        component: FaqComponent,
        data: { title: '' }
    },
    {
        path: LangRoutePages.tradeExport,
        component: TradeExportPageComponent,
        data: { title: '' }
    },
    {
        path: LangRoutePages.complaint,
        canActivate: [
            MsalGuard
        ],
        component: ComplaintPageComponent,
        data: { title: '' }
    },
    {
        path: LangRoutePages.admin,
        component: AdminPageComponent,
        data: { title: '' }
    },
    {
        path: LangRoutePages.services,

        children: [
            {
                path: '',
                component: ServicePageComponent,
            },
            {
                path: LangRoutePages.serviceApproved,

                children: [
                    {
                        path: '',
                        component: VolvoApprovedServicePageComponent,
                    },
                    {
                        path: LangRoutePages.approvedFAQ,
                        component: VolvoSelectedFAQPageComponent,

                    }
                ]
            },
            {
                path: LangRoutePages.serviceEconomy,

                children: [
                    {
                        path: '',
                        component: VolvoServiceEconomyPageComponent,
                    },
                    {
                        path: LangRoutePages.economyFAQ,
                        component: VolvoEconomyFAQPageComponent,

                    }
                ]
            },
            {
                path: LangRoutePages.serviceExport,
                component: VolvoExportPageComponent,

            },
            {
                path: LangRoutePages.serviceCustomisation,
                component: UTCustomisationPageComponent,

            },
            {
                path: LangRoutePages.volvoSelected,
                component: VolvoSelectedPageComponent,

            },
            {
                path: LangRoutePages.financing,
                component: FinancingPageComponent,

            },
            {
                path: LangRoutePages.finance,
                component: FinancePageComponent,

            },
            {
                path: LangRoutePages.serviceContract,
                component: ServiceContractPageComponent,

            },
            {
                path: LangRoutePages.customisation,
                component: CustomisationPageComponent,

            },
            {
                path: LangRoutePages.volvoConnect,
                component: VolvoConnectPageComponent,

            }
        ]
    },
    {
        path: LangRoutePages.aboutUs,

        children: [
            {
                path: '',
                component: AboutUsPageComponent,
            },
            {
                path: LangRoutePages.whoweare,
                component: WhoWeAreComponent,

            },
            {
                path: LangRoutePages.whatwehave,
                component: WhatWeHaveComponent,

            },
            {
                path: LangRoutePages.whatcustomersay,
                component: WhatCustomerSayComponent,

            }
        ]
    },
    {
        path: LangRoutePages.cookies,
        component: CookiesPageComponent,
        data: { title: '' }
    },
    {
        path: LangRoutePages.privacyPolicy,
        component: PrivacyPolicyPageComponent,
        data: { title: '' }
    },
    // {
    //     path: LangRoutePages.pageNotFound,
    //     component: StockPageComponent,
    //     data: { isNotFound: true }
    // },

    {
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
            { path: 'printTruck', component: PrintTruckPageComponent }
        ]
    },
    {
        path: LangRoutePages.truckModels,

        children: [
            {
                path: '',
                component: TruckModelPageComponent,
            },
            {
                path: LangRoutePages.volvoFH16,
                component: VolvoFH16PageComponent,

            },
            {
                path: LangRoutePages.volvoFH,
                component: VolvoFHPageComponent,

            },
            {
                path: LangRoutePages.volvoFM,
                component: volvoFMPageComponent,

            },
            {
                path: LangRoutePages.volvoFMX,
                component: volvoFMXPageComponent,

            }
        ]
    },
    {
        path: LangRoutePages.unsubscribe,
        component: UnsubscribePageComponent,
        data: { title: '' }
    },
    {
        path: LangRoutePages.assets,
        redirectTo: LangRoutePages.home
    },
    {
        path: '**',
        redirectTo: LangRoutePages.home
    }
];

export function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' }
    ]);
}

export function createUrlTranslateLoader(
    translate: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings
) {
    let hasLangInUrl = allLangRegEx.test(location.path());
    let langList = ['en', 'cz', 'dk', 'lt', 'ru', 'fr', 'es', 'no', 'it', 'fi', 'pl', 'hu', 'sk', 'si', 'at', 'ro', 'ba', 'mk', 'rs', 'hr', 'de', 'be-nl', 'be-fr', 'nl', 'bg', 'se', 'ee', 'lv', 'ch-de', 'ch-fr', 'ch-it', 'en-za', 'en-gb', 'en-tx'];

    let landId: string[], region: string;
    const txSiteUrls = new Set([
        'volvousedtrucks.co.uk',
        'tradeandexport.volvotrucks.com',
        'tradeandexporttrucks.co.uk',
        'tradeandexporttrucks.com',
        'usedtrucks.volvonorthandscotland.co.uk',
        'localhost'
    ]);

    // check if the query parameter campaignId is present in the URL
    // If it exists, add it to the session storage
    const urlParams = new URLSearchParams(window.location.search);
    const utmCampaign = urlParams.get('utm_campaign');
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    if (utmCampaign) {
        sessionStorage.setItem('campaignId', utmCampaign);
        sessionStorage.setItem('campaignSource', utmSource);
        sessionStorage.setItem('campaignMedium', utmMedium);
    }

    switch (window.location.hostname.replace('www.', '')) {
        case environment.ukSiteUrl:
            region = regionEnum.UK;
            landId = ['en-gb'];
            break;
        case environment.txGreenSiteUrl:
            region = regionEnum.TX;
            landId = ['en-tx'];
            break;
        case environment.selectedSiteUrl:
            region = "EU";
            break;
        default:
            if (txSiteUrls.has(window.location.hostname.replace('www.', ''))) {
                region = regionEnum.TX;
                landId = ['en-tx'];
            } else {
                landId = ['en'];
            }
            break;
    }

    if (region !== "EU") {
        langList = landId;
    }
    if (location.path().toString().includes('regions')) {
        var path = location.path().toString().replace(allLangRegEx, '/');
        location.go(path);
        settings.alwaysSetPrefix = false;
        return new ManualParserLoader(translate, location, settings, langList, 'ROUTES.');
    }
    if (hasLangInUrl) {
        var path = location.path();//.toString().replace(allLangRegEx, '/');
        settings.alwaysSetPrefix = true;
        location.go(path);
        return new ManualParserLoader(translate, location, settings, langList, 'ROUTES.');
    } else {
        var path = location.path();
        settings.alwaysSetPrefix = true;
        location.go(path);
        return new ManualParserLoader(translate, location, settings, langList, 'ROUTES.');
    }
}

@NgModule({
    providers: [

    ],
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            relativeLinkResolution: 'legacy',
            useHash: false
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: createUrlTranslateLoader,
                deps: [TranslateService, Location, LocalizeRouterSettings /*, HttpClient*/]
            }
        })
    ],
    exports: [RouterModule, LocalizeRouterModule, TranslateModule]
})
export class AppRoutingModule {
    lang: LocalesStateLangList;

    constructor(
        private router: Router,
        private localizeRouterService: LocalizeRouterService,
    ) {
        this.router.errorHandler = (e) => {
            console.log({ NavigationError: e });

            this.router.navigate([HtmlRoutePages.home]);
        };
    }
}
