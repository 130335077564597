<form [formGroup]="form" (ngSubmit)="onSubmit()" class="PopupOLD-form">
  <div class="PopupOLD-input">
    <label for="subject-select" class="Vfs-input-title" data-cy="contactpage-maintitle">
      {{ 'contact/form/subject/title' | lowercase | translate }}
    </label>

    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch" data-cy="contactpage-subject">
      <mat-select formControlName="subject" class="fontcolor" 
        [placeholder]="'contact/form/subject/placeholder' | lowercase | translate" id="subject-select">
        <mat-option class="Option" value="Sales and Product Inquiries" data-cy="contactpage-subjectitem1">
          {{ 'contact/form/subject/SalesAndProduct' | lowercase | translate }}</mat-option>
        <mat-option class="Option" value="Financing Service" data-cy="contactpage-subjectitem2">
          {{ 'contact/form/subject/financingService' | lowercase | translate }}</mat-option>
        <mat-option class="Option" value="Product and Service Support" data-cy="contactpage-subjectitem3">
          {{ 'contact/form/subject/productAndServiceSupport' | lowercase | translate }}</mat-option>
        <mat-option class="Option" value="Feedback" data-cy="contactpage-subjectitem4">{{
          'contact/form/subject/feedback' | lowercase | translate
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="PopupOLD-input">
    <label class="Vfs-input-title" data-cy="contactpage-countrylable">
      {{ 'form/country' | lowercase | translate }}
    </label>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <mat-select formControlName="location" [(ngModel)]="autoSelectCountry.id"
        [placeholder]="'form/dropdownCountry' | lowercase | translate" (selectionChange)="onSelect($event)"
        data-cy="contactpage-countrydropdown">
        <mat-option class="volvofont CPrimary" *ngFor="let country of dealershipCountries" [value]="country.id"
          data-cy="contactpage-countrydditems">{{ country.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="PopupOLD-input">
    <label class="Vfs-input-title" data-cy="contactpage-dealershiplable">
      {{ 'form/dealership' | lowercase | translate }} &nbsp;<span>({{ 'form/optional' | lowercase | translate }})</span>
    </label>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <mat-select [disabled]="currentLang === undefined"
        [placeholder]="'form/dropdownDealership' | lowercase | translate" formControlName="city"
        data-cy="contactpage-dealershipdropdown">
        <mat-option class="Option" *ngFor="let city of showCities" [value]="city"
          data-cy="contactpage-dealershipdditems">
          {{ city }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="PopupOLD-input">
    <label class="Vfs-input-title" for="NameInput">{{ 'form/fullName' | lowercase | translate }}</label>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <input formControlName="fullName" matInput data-cy="contactpage-fullname" id="NameInput"/>
    </mat-form-field>
  </div>
  <div class="PopupOLD-input">
    <label class="Vfs-input-title" for="phoneInput">{{ 'form/phoneNumber' | lowercase | translate }}</label>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <input formControlName="phone" type="tel" matInput data-cy="contactpage-phnumber" id="phoneInput"/>
    </mat-form-field>
  </div>

  <div class="PopupOLD-input">
    <label class="Vfs-input-title" for="emailInput">{{ 'form/email' | lowercase | translate }}</label>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <input formControlName="email" type="email" id="emailInput" matInput data-cy="contactpage-email" />
    </mat-form-field>
  </div>

  <div class="PopupOLD-input">
    <label class="Vfs-input-title" for="companyNameInput">{{ 'form/fullcompanyname' | lowercase | translate }} &nbsp;<span>({{ 'form/optional' | lowercase | translate }})</span></label>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <input formControlName="company" id="companyNameInput" matInput data-cy="contactpage-companyName" />
    </mat-form-field>
  </div>

  <div class="PopupOLD-input">
    <label class="Vfs-input-title" for="messageInput">
      {{ 'form/message' | lowercase | translate }} &nbsp;<span>({{ 'form/optional' | lowercase | translate }})</span>
    </label>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <textarea formControlName="message" matInput rows="5" id="messageInput"></textarea>
    </mat-form-field>
  </div>

  <div class="MatInputSearchVfs checkboxPadding">
    <mat-checkbox formControlName="privacy" (click)="recaptcha()" class="big" data-cy="contactpage-privacychkbox" (keydown.enter)="onCheckboxToggle($event)">
      <div class="Vfs-privacy">
        {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
        <a class="regularFont" href="{{ checkbox }}" target="_blank" style="text-decoration: underline;">
          <span>{{ 'form/privacyPolicy' | lowercase | translate }}</span>
        </a>
      </div>
    </mat-checkbox>
  </div>
  
  <div *ngIf="showRecaptcha" class="PopupOLD-input" style="margin-bottom: 15px;display: inline-block;">
    <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required></re-captcha>
  </div>

  <br />
  <div class="buttonCss">
    <button  type="submit" 
       data-cy="contactpage-submitbtn" [ngClass]="this.form.invalid || !reCaptchaRes?'buttonCss-btnDisbaleColor':''"  [disabled]="this.form.invalid || !reCaptchaRes" tabindex="0">
      {{ 'form/sendMessage' | lowercase | translate }}
    </button>
  </div>
</form>
