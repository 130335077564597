import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
@Component({
  selector: 'volvo-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  account: AccountInfo = null;
  constructor(private msalService: MsalService
  ) {

  }
  ngOnInit(): void {
    this.account = this.msalService.instance.getActiveAccount();
  }

  name() {
    let text = this.account.name;
    let cleanedText = text.replace(/\s*\([^)]*\)/g, '');
    return cleanedText;
  }
}
