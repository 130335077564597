<section *ngIf="region === regionEnum.UK" class="compliance">
  <div class="compliance-text">
    <p>
      <i>
        {{ 'home/compliancetext' | lowercase | translate }}
      </i>
    </p>
  </div>
</section>

<div class="txfooter" *ngIf="isInitialized">
  <div class="txfooter-top footerdisplay"
    [ngClass]="region === 'TX' ? 'txMaxWidth' : isSelectedSite ? 'selectedMaxWidth' : ''">
    <div *ngFor="let list of menu;">
      <div class="Footer-title" *ngIf="!list.submenu">
        <a [routerLink]="list.path | localize" style="color:#323232;">{{ list.label | lowercase | translate }}</a>
      </div>
      <div class="txfooter-title" *ngIf="list.submenu">
        {{ list.label | lowercase | translate}}
        <div *ngFor="let item of list.submenu;">
          <div *ngIf="!item.navigateToOtherSite">
            <a [routerLink]="item.path | localize" *ngIf="!item.scrollToId" class="txfooter-link"
              (click)="logTruckModelButtonClick(item.name)">
              <div [innerHTML]="item.label | lowercase | translate"></div>
            </a>
            <a [routerLink]="item.path | localize" *ngIf="item.scrollToId" class="txfooter-link"
              [queryParams]="{ path: item.scrollToId }">
              <div [innerHTML]="item.label | lowercase | translate"></div>
            </a>
          </div>
          <a [href]="item.path" target="_blank" *ngIf="item.navigateToOtherSite" class="txfooter-link"
            (click)="logTruckModelButtonClick(item.name)">
            <div
              *ngIf="(item.label==='components/footer/facebook' || item.label==='components/footer/youtube' || item.label==='uk/components/footer/linkedIn' || item.label==='components/footer/instagram')">
              <img [src]="item.imagepath" class="txfooter-mediaImage"
                [alt]="(item.label | lowercase | translate) + ' icon'">
            </div>{{ item.label |lowercase | translate }}
            <div
              *ngIf="(item.label==='components/footer/facebook' || item.label==='components/footer/youtube'|| item.label==='uk/components/footer/linkedIn' || item.label==='components/footer/instagram')">
              <img [src]="assetService.getAssetUrl('assets/icons/export.svg')" class="txfooter-mediaImagewindow"
                [alt]="'Open ' + (item.label | lowercase | translate)">
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footerdisplay-mobBtns">
    <mat-accordion multi="true" class="VolvoAccordionFooter">
      <div *ngFor="let list of menu;let i = index;" class="Footer-item">
        <div class="txfooter-title footerheight mobileFooter" *ngIf="!list.submenu">
          <a [routerLink]="list.path | localize" style="color:#323232;">{{ list.label | lowercase | translate }}</a>
        </div>
        <div *ngIf="list.submenu">
          <mat-expansion-panel (afterExpand)="scroll('panel-'+i)">
            <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
              <mat-panel-title>
                <div class="txfooter-title footerheight">
                  {{ list.label | lowercase | translate }}
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div id="panel-{{i}}" class="txfooter-mobileDiv">
              <div *ngFor="let item of list.submenu;">
                <div *ngIf="!item.navigateToOtherSite">
                  <a [routerLink]="item.path | localize" *ngIf="!item.scrollToId" class="txfooter-link"
                    (click)="logTruckModelButtonClick(item.name)">
                    <div [innerHTML]="item.label | lowercase | translate"></div>
                  </a>
                  <a [routerLink]="item.path | localize" *ngIf="item.scrollToId" class="txfooter-link"
                    [queryParams]="{ path: item.scrollToId }">
                    <div [innerHTML]="item.label | lowercase | translate"></div>
                  </a>
                </div>
                <a [href]="item.path" target="_blank" *ngIf="item.navigateToOtherSite" class="txfooter-link"
                  (click)="logTruckModelButtonClick(item.name)">
                  <div
                    *ngIf="(item.label==='components/footer/facebook' || item.label==='components/footer/youtube' || item.label==='uk/components/footer/linkedIn' || item.label==='components/footer/instagram')">
                    <img [src]="item.imagepath" class="txfooter-mediaImage"
                      [alt]="(item.label | lowercase | translate) + ' icon'">
                  </div>{{ item.label |lowercase | translate }}
                  <div
                    *ngIf="(item.label==='components/footer/facebook' || item.label==='components/footer/youtube'|| item.label==='uk/components/footer/linkedIn' || item.label==='components/footer/instagram')">
                    <img [src]="assetService.getAssetUrl('assets/icons/export.svg')" class="txfooter-mediaImagewindow"
                      [alt]="'Open ' + (item.label | lowercase | translate)">
                  </div>
                </a>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </mat-accordion>
  </div>
  <div class="footerdisplay-copyRight">
    <div class="txfooter-bottom" [ngClass]="region === 'TX' ? 'txMargin' : ''">
      <div class="txfooter-bottomcookielinks" *ngIf="!isSelectedSite">
        <a href="https://www.volvotrucks.co.uk/en-gb/tools/cookies.html" target="_blank" class="txfooter-bottomLink">{{
          "uk/components/footer/cookieStatement" | lowercase | translate }}</a>
        <span class="Footer-bottomDel"></span>
        <a href="https://www.volvotrucks.co.uk/en-gb/services/agreements/data-management-agreement.html" target="_blank"
          class="txfooter-bottomLink wordBreak">{{ "uk/components/footer/dataManagementAgreement" | lowercase |
          translate }}</a>
        <span class="Footer-bottomDel showhidedel"></span>
        <a href="https://www.volvotrucks.co.uk/en-gb/tools/privacy.html" target="_blank" class="txfooter-bottomLink">{{
          "uk/components/footer/privacyPolicy" | lowercase | translate }}</a>
        <span class="Footer-bottomDel"></span>
        <a href="assets/files/Volvo_Group_UK_modern-slavery-statement.pdf" target="_blank"
          class="txfooter-bottomLink wordBreak" style="text-decoration: none !important;">{{
          "uk/components/footer/modernSlaveryStatement" | lowercase | translate }}</a>
        <span class="Footer-bottomDel showhidedel-tablet"></span>
        <a href="assets/files/Volvo Group UK - Used Trucks T&Cs 2021.pdf" target="_blank" class="txfooter-bottomLink"
          style="text-decoration: none !important;">{{ "uk/components/footer/Terms&Conditions" | lowercase | translate
          }}</a>
        <span class="Footer-bottomDel"></span>
        <a [routerLink]="HtmlRoutePages.complaint | localize" class="txfooter-bottomLink"
          style="text-decoration: none !important;">{{ "uk/components/footer/complaints" | lowercase | translate }}</a>
      </div>
    </div>
    <div class="txfooter-bottomLogo"
      [ngClass]="region === 'TX' ? 'txMaxWidth' : isSelectedSite ? 'selectedMaxWidth' : ''">
      <div *isSelectedSite class="addMargin">
        <a href="https://www.volvotrucks.com/" target="_blank" class="txfooter-bottomSelectedLink">Volvotrucks.com</a>
        <span class="Footer-bottomDel"></span>
        <a [routerLink]="cookieMenuItems | localize" class="txfooter-bottomSelectedLink">
          {{ 'pages/cookies' | lowercase | translate }}
        </a>
        <span class="Footer-bottomDel"></span>
        <a href="https://www.volvogroup.com/en/tools/privacy.html" target="_blank"
          class="txfooter-bottomSelectedLink">{{ 'components/footer/privacyPolicy' | lowercase | translate }}</a>

        <span *ngIf="!environment.production" class="Footer-bottomDel"></span>
        <a *ngIf="!isLoggedIn() && !environment.production" href="#" (click)="loginAndNavigate($event)"
          class="txfooter-bottomSelectedLink">
          Admin Login
        </a>
        <a *ngIf="isLoggedIn() && !environment.production" href="#" (click)="logout($event)"
          class="txfooter-bottomSelectedLink">
          Admin Logout
        </a>

      </div>
      <div>
        <div class="addMargin1">
          <span class="txfooter-copyRightMargin" ngClass="isSelectedSite? customColor : ''">
            © {{ "components/footer/copyright" | lowercase | translate }} {{ currentYear }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<volvo-scroll-top class="scrolltop"></volvo-scroll-top>